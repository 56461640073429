import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UploadedFileModel, FileUploadResponse, SectionSubType } from '@fieldos/models';
import { filterEmpty } from '@fieldos/utils';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadDataService {
  private readonly _http = inject(HttpClient);

  uploadSectionFile(
    file: File,
    sectionId: string,
    sectionType: SectionSubType,
    value?: string
  ): Observable<UploadedFileModel> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('module', 'workorder');
    formData.append('sectionId', sectionId);
    formData.append('type', sectionType);
    formData.append('value', value || '');

    return this._http.post<FileUploadResponse>('/upload', formData).pipe(
      map(
        (f) =>
          ({
            description: f.description,
            id: f.id,
            mimeType: f.mimeType,
            name: file.name,
            size: f.size,
            thumbnailUrl: f.thumbnailUrl,
            url: f.url,
          }) as UploadedFileModel
      )
    );
  }

  uploadFileWithProgress(file: File, module: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('module', module);

    return this._http
      .post<FileUploadResponse>('/upload', formData, {
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        map((event: HttpEvent<FileUploadResponse | number>) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            return Math.round((100 / event.total) * event.loaded);
          } else {
            return (event as HttpResponse<FileUploadResponse>).body;
          }
        }),
        filterEmpty(),
        map((event: number | FileUploadResponse) => {
          if (typeof event === 'number') {
            return event;
          }

          return {
            description: event.description,
            id: event.id,
            mimeType: event.mimeType,
            name: file.name,
            size: event.size,
            thumbnailUrl: event.thumbnailUrl,
            url: event.url,
            value: event.value,
          } as UploadedFileModel;
        })
      );
  }

  uploadFile(file: File, module: string, type?: string): Observable<UploadedFileModel> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('module', module);

    if (type) {
      formData.append('type', type);
    }

    return this._http.post<FileUploadResponse>('/upload', formData).pipe(
      map(
        (event: FileUploadResponse) =>
          ({
            description: event.description,
            id: event.id,
            mimeType: event.mimeType,
            name: file.name,
            size: event.size,
            thumbnailUrl: event.thumbnailUrl,
            url: event.url,
            value: event.value,
          }) as UploadedFileModel
      )
    );
  }

  uploadSectionFileWithProgress(
    file: File,
    sectionId: string,
    sectionType: SectionSubType,
    value = ''
  ): Observable<number | UploadedFileModel> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('module', 'workorder');
    formData.append('sectionId', sectionId);
    formData.append('type', sectionType);
    ``;
    formData.append('value', value);

    return this._http
      .post<FileUploadResponse>('/upload', formData, {
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        map((event: HttpEvent<FileUploadResponse | number>) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            return Math.round((100 / event.total) * event.loaded);
          } else {
            return (event as HttpResponse<FileUploadResponse>).body;
          }
        }),
        filterEmpty(),
        map((event: number | FileUploadResponse) => {
          if (typeof event === 'number') {
            return event;
          }

          return {
            description: event.description,
            id: event.id,
            mimeType: event.mimeType,
            name: file.name,
            size: event.size,
            thumbnailUrl: event.thumbnailUrl,
            url: event.url,
            value: event.value,
          } as UploadedFileModel;
        })
      );
  }
}
