import { UploadedFileModel } from '@fieldos/models';

export interface FileGalleryDialogData {
  files: UploadedFileModel[];
  selectedIndex: number;
  options: {
    removeDisabled: boolean;
  };
  onDelete: (index: number) => void;
}
