import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileModule } from '@fieldos/models';

export type ProcessUploadedFileFormType = {
  folderId: FormControl<number>;
  module: FormControl<FileModule>;
  entityId: FormControl<number>;
  issueDate: FormControl<Date | null>;
  expiryDate: FormControl<Date | null>;
  tags: FormControl<number[]>;
};

export const createProcessUploadedFilesForm = (
  folderId: number,
  entityId?: number,
  module?: FileModule
): FormGroup<ProcessUploadedFileFormType> =>
  new FormGroup({
    folderId: new FormControl<number>(folderId, { nonNullable: true }),
    module: new FormControl<FileModule>(
      {
        value: module ?? FileModule.Files,
        disabled: !!module,
      },
      {
        nonNullable: true,
      }
    ),
    entityId: new FormControl<number>(
      {
        value: entityId ?? 0,
        disabled: !!entityId,
      },
      {
        nonNullable: true,
      }
    ),
    issueDate: new FormControl<Date | null>(null, {
      validators: Validators.required,
    }),
    expiryDate: new FormControl<Date | null>(null, {
      validators: Validators.required,
    }),
    tags: new FormControl<number[]>([], {
      nonNullable: true,
    }),
  });
