import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DatetimePickerComponent,
  SelectFieldComponent,
} from '@fieldos/components';
import { DialogService } from '@fieldos/core';
import { FileModule, UploadedFileModel } from '@fieldos/models';
import { FileEntityNamePipe } from '@fieldos/pipes';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom, tap } from 'rxjs';
import { FolderNamePipe } from '../../utils';
import { SelectFileEntityDialogComponent } from '../select-file-entity-dialog/select-file-entity-dialog.component';
import { SelectFolderDialogComponent } from '../select-folder-dialog/select-folder-dialog.component';
import { FILE_MODULES } from './file-modules.provider';
import {
  createProcessUploadedFilesForm,
  ProcessUploadedFileFormType,
} from './process-uploaded-files.form';
import { ProcessedFileModel } from './process-uploaded-files.models';

@Component({
  selector: 'app-process-uploaded-files',
  templateUrl: './process-uploaded-files.component.html',
  standalone: true,
  imports: [
    MatTableModule,
    TranslocoModule,
    DatePipe,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    SelectFieldComponent,
    DatetimePickerComponent,
    FolderNamePipe,
    ReactiveFormsModule,
    FileEntityNamePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessUploadedFilesComponent {
  constructor() {
    effect(() => {
      const files = this.files();

      this.dataSource.data = files;
    });

    effect(() => {
      if (this.files().length && this.filesFormArray.length === 0) {
        this.filesFormArray.clear();

        this.files().forEach(() => {
          this.filesFormArray.push(
            createProcessUploadedFilesForm(
              this.folderId(),
              this.entityId(),
              this.module()
            )
          );
        });
      }
    });

    this.filesFormArray.valueChanges
      .pipe(
        takeUntilDestroyed(),
        tap(() => this.valueChange.emit(this.filesFormArray.getRawValue()))
      )
      .subscribe();
  }

  public readonly files = input.required<UploadedFileModel[]>();
  public readonly folderId = input.required<number>();
  public readonly entityId = input<number>();
  public readonly module = input<FileModule>();

  public readonly valueChange = output<ProcessedFileModel[]>();

  protected readonly fileModules = inject(FILE_MODULES);

  protected readonly dataSource = new MatTableDataSource<UploadedFileModel>([]);

  protected readonly columns = [
    'name',
    'module',
    'folderName',
    'entityName',
    'issueDate',
    'expiryDate',
    'tags',
    'isLoading',
  ];

  protected readonly filesFormArray = new FormArray<
    FormGroup<ProcessUploadedFileFormType>
  >([]);

  private readonly _dialog = inject(DialogService);
  private readonly _detector = inject(ChangeDetectorRef);

  protected async editEntityId(index: number): Promise<void> {
    const moduleId = this.filesFormArray
      .at(index)
      .get('module')
      ?.getRawValue() as FileModule;

    const entityId = await lastValueFrom(
      this._dialog.openDialog<FileModule, number>(
        SelectFileEntityDialogComponent,
        {
          data: moduleId,
        }
      )
    );

    if (entityId) {
      this.filesFormArray.at(index).get('entityId')?.setValue(entityId);
    }
  }

  protected async editFileFolder(index: number): Promise<void> {
    const existingFolderId = this.filesFormArray.at(index).get('folderId')
      ?.value;

    const folderId = await lastValueFrom(
      this._dialog.openDialog<number, number>(SelectFolderDialogComponent, {
        data: existingFolderId,
        minHeight: '60vh',
      })
    );

    if (typeof folderId === 'number') {
      this.filesFormArray.at(index).get('folderId')?.setValue(folderId);

      this._detector.detectChanges();
    }
  }
}
