import { inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { FileModel, FileModule } from '@fieldos/models';
import {
  patchState,
  signalStoreFeature,
  withMethods,
  withState,
} from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { ToastStore } from '../toast.store';

interface FileStoreState {
  loading: boolean;
  selectedFolderId: number;
}

export const withFilesStore = (module: FileModule) =>
  signalStoreFeature(
    withEntities<FileModel>(),
    withState<FileStoreState>({
      loading: false,
      selectedFolderId: 0,
    }),
    withMethods(
      (store, http = inject(HttpService), toast = inject(ToastStore)) => ({
        async fetchFiles(entityId: number | string): Promise<void> {
          try {
            const files = await http.get<FileModel[]>(
              `/files?moduleId=${module}&entityId=${entityId}`
            );
            patchState(store, setAllEntities(files));
          } catch (error) {
            toast.showErrorToast('files.fetch_files.error');
          }
        },
        setSelectedFolderId(folderId: number): void {
          patchState(store, { selectedFolderId: folderId });
        },
      })
    )
  );
