<div class="flex flex-col p-4 border-r h-full">
  <h6 class="text-lg font-bold">
    {{ 'files.folders' | transloco }}
  </h6>

  <mat-divider class="my-2" />

  <mat-tree
    [dataSource]="treeUtils.dataSource"
    [treeControl]="treeUtils.treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      matTreeNodePaddingIndent="16"
      class="group cursor-pointer"
      (click)="selectFolder.emit(node.id)">
      <div class="flex gap-2 w-full">
        @if (node.children.length === 0) {
          <button mat-icon-button disabled>
            <mat-icon
              class="mat-icon-rtl-mirror"
              svgIcon="heroicons_solid:{{
                node.children.length ? 'folder-arrow-down' : 'folder'
              }}" />
          </button>
        }

        <div
          [class.font-bold]="node.id === selectedFolderId()"
          [class.text-primary]="node.id === selectedFolderId()"
          class="truncate flex-shrink flex-grow">
          {{ node.name | transloco }}
        </div>

        <div class="hidden group-hover:flex">
          @if (node.children.length === 0) {
            <mat-icon
              class="cursor-pointer"
              (click)="openRemoveFolder(node.id); $event.stopPropagation()"
              color="warn">
              clear
            </mat-icon>
          }

          <mat-icon
            class="cursor-pointer"
            (click)="openAddFolder(node.id); $event.stopPropagation()"
            color="primary">
            add
          </mat-icon>
        </div>
      </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodePaddingIndent="16"
      class="group cursor-pointer"
      (click)="
        selectFolder.emit(node.id);
        !treeUtils.treeControl.isExpanded(node)
          ? treeUtils.treeControl.expand(node)
          : undefined
      ">
      <div class="flex gap-2 w-full">
        <button
          mat-icon-button
          [attr.aria-label]="'Toggle ' + node.name"
          (click)="
            treeUtils.treeControl.toggle(node); $event.stopPropagation()
          ">
          <mat-icon
            class="mat-icon-rtl-mirror"
            svgIcon="heroicons_solid:{{
              treeUtils.treeControl.isExpanded(node)
                ? 'folder-open'
                : 'folder-arrow-down'
            }}" />
        </button>

        <div
          [class.font-bold]="node.id === selectedFolderId()"
          [class.text-primary]="node.id === selectedFolderId()"
          class="truncate flex-shrink flex-grow">
          {{ node.name | transloco }}
        </div>

        <div class="hidden group-hover:flex">
          @if (node.children.length === 0) {
            <mat-icon
              class="cursor-pointer"
              (click)="openRemoveFolder(node.id); $event.stopPropagation()"
              color="warn">
              clear
            </mat-icon>
          }

          @if (!hideCreateButton()) {
            <mat-icon
              class="hidden group-hover:block cursor-pointer"
              (click)="openAddFolder(node.id); $event.stopPropagation()"
              color="primary">
              add
            </mat-icon>
          }
        </div>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
