import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SelectFieldComponent } from '@fieldos/components';
import { DomainEntityBase } from '@fieldos/models';
import { getFileModuleOptions } from '@fieldos/pipes';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-select-file-entity-dialog',
  templateUrl: './select-file-entity-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    TranslocoModule,
    SelectFieldComponent,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFileEntityDialogComponent implements OnInit {
  protected readonly formCtrl = new FormControl<number>(0, {
    validators: Validators.min(1),
  });
  protected readonly options = signal<DomainEntityBase[] | null>([]);

  private readonly _moduleId = inject<number>(MAT_DIALOG_DATA);
  private readonly _getModuleOptions = getFileModuleOptions();

  ngOnInit(): void {
    const options = this._getModuleOptions(this._moduleId);

    this.options.set(options ? Object.values(options) : null);
  }
}
