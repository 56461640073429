import { Component, inject, input, output, signal } from '@angular/core';
import { SectionsFormStore } from '@fieldos/business/sections/sections-form/sections-form.store';
import {
  ChecklistTask,
  ChecklistTaskValueBase,
  SectionBasedEntity,
} from '@fieldos/models';

@Component({
  selector: '',
  template: '',
})
export class ChecklistTaskViewerBaseComponent<
  T extends ChecklistTask = ChecklistTask,
> {
  public readonly value = input<ChecklistTaskValueBase<T['defaultValue']>>();
  public readonly entity = input<SectionBasedEntity>();

  public readonly task = input.required<T>();

  public readonly valueChange =
    output<ChecklistTaskValueBase<T['defaultValue']>>();

  protected readonly isExecuted = signal<boolean>(false);

  protected readonly sectionsFormStore = inject(SectionsFormStore, {
    optional: true,
  });

  async onValueChange(value: T['defaultValue']): Promise<void> {
    const executed = !!(await this.executeAction(value));

    this.isExecuted.set(executed);

    this.valueChange.emit({
      value,
      executed,
    });
  }

  protected async executeAction(
    value: T['defaultValue']
  ): Promise<T['defaultValue'] | false> {
    return Promise.resolve(value);
  }
}
