import {
  ChecklistTask,
  ChecklistTaskConditionActionTask,
  ChecklistTaskValueBase,
} from '../checklist-task';

export enum ChecklistYesNoNaTaskOption {
  No = 0,
  Yes = 1,
  NA = -1,
}

export type ChecklistYesNoNATaskConditionals = Record<
  ChecklistYesNoNaTaskOption,
  ChecklistTaskConditionActionTask | null
>;

export type ChecklistYesNoNaTask = ChecklistTask<
  ChecklistYesNoNaTaskOption,
  ChecklistYesNoNATaskConditionals
>;

export type ChecklistYesNoNaTaskValue =
  ChecklistTaskValueBase<ChecklistYesNoNaTaskOption>;
