import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogService, FileUploadFacade } from '@fieldos/core';
import { HasPermissionDirective } from '@fieldos/directives';
import { ChatMessage, UploadedFileModel } from '@fieldos/models';
import { AuthStore } from '@fieldos/store/index';
import { textColorClasses } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import { ChatMessagesComponent } from './chat-messages/chat-messages.component';
import { ChatMessageItem, SendChatMessageModel } from './chat-window.models';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    NgClass,
    DatePipe,
    TranslocoModule,
    ReactiveFormsModule,
    CdkScrollableModule,
    NgTemplateOutlet,
    MatProgressSpinnerModule,
    MatTooltipModule,
    HasPermissionDirective,
    ChatMessagesComponent,
  ],
})
export class ChatWindowComponent {
  public readonly chatMessages = input.required<ChatMessage[]>();
  public readonly showClearButton = input<boolean>(true);
  public readonly loading = input<boolean>(false);

  public readonly drawerClose = output<void>();
  public readonly sendMessage = output<SendChatMessageModel>();
  public readonly closeClick = output<void>();

  protected readonly userId = inject(AuthStore).userId;
  protected readonly messages = signal<ChatMessageItem[]>([]);

  private readonly _fileUploader = inject(FileUploadFacade);
  private readonly _dialog = inject(DialogService);

  protected get userColor(): Record<number, string> {
    const users: number[] = [];

    this.messages().forEach((message) => {
      if (users.includes(message.userId)) {
        return;
      }

      users.push(message.userId);
    });

    return users.reduce(
      (acc, userId, index) => ({
        ...acc,
        [userId]: textColorClasses[index % textColorClasses.length],
      }),
      {}
    );
  }

  protected readonly trackByFn = (index: number, message: ChatMessage) =>
    message.date.toString();

  protected onSendMessage(model: SendChatMessageModel): void {
    this.sendMessage.emit(model);
  }

  protected async onFileUploaded(uploadedFile: File): Promise<void> {
    try {
      const file = await lastValueFrom(
        this._fileUploader.uploadFile(uploadedFile, 'chat', 'chat')
      );

      const component = await import(
        './chat-window-add-file-dialog/chat-window-add-file-dialog.component'
      ).then((m) => m.ChatWindowAddFileDialogComponent);

      const result = await lastValueFrom(
        this._dialog.openDialog<UploadedFileModel, SendChatMessageModel>(component, {
          data: file,
          autoFocus: false,
        })
      );

      if (result) {
        this.sendMessage.emit(result);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
