<div class="flex gap-2">
  <mat-button-toggle-group
    class="flex-grow mat-primary"
    [value]="value()?.value"
    (change)="onValueChange($event.value)">
    <mat-button-toggle
      class="flex-grow"
      [disabled]="value()?.executed || isExecuted()"
      [value]="1">
      {{ 'common.yes' | transloco }}
    </mat-button-toggle>
    <mat-button-toggle
      class="flex-grow"
      [disabled]="value()?.executed || isExecuted()"
      [value]="0">
      {{ 'common.no' | transloco }}
    </mat-button-toggle>
    <mat-button-toggle
      class="flex-grow"
      [disabled]="value()?.executed || isExecuted()"
      [value]="-1">
      {{ 'common.not_available' | transloco }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
