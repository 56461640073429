import { InjectionToken } from '@angular/core';
import { DomainEntityBase, FileModule } from '@fieldos/models';

export const FILE_MODULES = new InjectionToken('FILE_MODULES', {
  factory: (): DomainEntityBase[] => {
    const values = Object.values(FileModule)
      .filter((e) => typeof e === 'number')
      .map((module) => ({
        id: module,
        name: `files.module.${module}`,
      }));

    return values;
  },
});
