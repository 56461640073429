export enum FileModule {
  Customers = 2,
  Contracts = 3,
  Locations = 4,
  Assets = 5,
  Parts = 6,
  Users = 7,
  Contractors = 8,
  ServiceRequests = 9,
  WorkOrders = 10,
  Maintenance = 11,
  Files = 12,
}
