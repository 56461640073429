import { inject } from '@angular/core';
import {
  ActionsCellRendererComponent,
  ActionsCellRendererParams,
  DateCellRendererComponent,
  DateFilterComponent,
  DateFloatingFilterComponent,
  DEFAULT_COLUMN,
} from '@fieldos/components';
import { ClaimsStore } from '@fieldos/store/index';
import { ColDef } from 'ag-grid-community';

export const createFolderFileColumns = () => {
  const defaultCol = inject(DEFAULT_COLUMN);
  const claimStore = inject(ClaimsStore);

  const actions = [] as ActionsCellRendererParams['actions'];

  if (claimStore.hasClaim('files.edit')) {
    actions.push({
      icon: 'edit',
      id: 'edit',
    });
  }

  if (claimStore.hasClaim('files.delete')) {
    actions.push({
      icon: 'delete',
      id: 'delete',
      color: 'warn',
    });
  }

  return [
    {
      ...defaultCol,
      field: 'name',
      headerName: 'common.name',
    },
    {
      ...defaultCol,
      field: 'moduleName',
      headerName: 'files.module_name',
    },
    {
      ...defaultCol,
      field: 'folderName',
      headerName: 'files.folder',
    },
    {
      ...defaultCol,
      field: 'entityName',
      headerName: 'files.entity_name',
    },
    {
      ...defaultCol,
      field: 'issueDate',
      cellRenderer: DateCellRendererComponent,
      filter: DateFilterComponent,
      floatingFilter: DateFloatingFilterComponent,
      headerName: 'common.issue_date',
    },
    {
      ...defaultCol,
      field: 'expiryDate',
      cellRenderer: DateCellRendererComponent,
      filter: DateFilterComponent,
      floatingFilter: DateFloatingFilterComponent,
      headerName: 'common.expiry_date',
    },

    {
      ...defaultCol,
      field: 'tags',
      headerName: 'common.tags',
    },
    {
      headerName: 'common.actions',
      cellRenderer: ActionsCellRendererComponent,
      cellRendererParams: {
        actions,
      } as ActionsCellRendererParams,
      resizable: false,
      pinned: 'right',
      cellClass: 'px-0',
      width: 120,
      minWidth: 120,
      maxWidth: 120,
    },
  ] as ColDef[];
};
