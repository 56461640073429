<h1 mat-dialog-title class="text-lg text-center font-medium">
  {{ 'files.select_folder_dialog.title' | transloco }}
</h1>

<mat-dialog-content>
  <app-folder-tree
    [selectedFolderId]="selectedFolderId()"
    (selectFolder)="selectedFolderId.set($event)"
    defaultExpanded />
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'common.cancel' | transloco }}
  </button>

  <button mat-button [mat-dialog-close]="selectedFolderId()" color="primary">
    {{ 'common.save' | transloco }}
  </button>
</mat-dialog-actions>
