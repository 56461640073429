import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FileGalleryComponent } from '@fieldos/components';
import { ChatMessage, UploadedFileModel } from '@fieldos/models';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, map } from 'rxjs';
import { ChatMessagesDialogComponent } from './chat-messages-dialog/chat-messages-dialog.component';
import { ChatMessagesDialogData } from './chat-messages-dialog/chat-messages-dialog.models';
import { FilesDialogComponent } from './files-dialog/files-dialog.component';
import { HtmlEditorDialogComponent } from './html-editor-dialog/html-editor-dialog.component';
import { TextInputDialogComponent } from './text-input-dialog/text-input-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private _dialog: MatDialog,
    private _confirm: FuseConfirmationService
  ) {}

  showConfirmDialog(
    title: string = 'box_confirm_dialog.title',
    message: string = 'box_confirm_dialog.message',
    confirmLabel: string = 'common.yes',
    cancelLabel: string = 'common.no'
  ): Observable<boolean> {
    return this._confirm
      .open({
        title,
        message,
        actions: {
          confirm: {
            label: confirmLabel,
          },
          cancel: {
            label: cancelLabel,
          },
        },
      })
      .afterClosed()
      .pipe(map((result: string) => result === 'confirmed'));
  }

  openDialog<TDialogData, TDialogResult>(
    component: Type<any>,
    config?: MatDialogConfig<TDialogData>
  ): Observable<TDialogResult> {
    return this._dialog
      .open(component, {
        minWidth: '420px',
        ...config,
      })
      .afterClosed();
  }

  openTextFieldDialog(
    title: string,
    placeholder: string,
    value = '',
    type: 'text' | 'number' | 'password' | 'link' = 'text'
  ): Observable<string> {
    return this.openDialog(TextInputDialogComponent, {
      data: {
        title,
        placeholder,
        value,
        type,
      },
    });
  }

  openChatDialog(
    messages: ChatMessage[],
    title: string,
    canAddFiles = true
  ): Observable<ChatMessagesDialogData['messages']> {
    return this._dialog
      .open(ChatMessagesDialogComponent, {
        data: {
          messages,
          title,
          canAddFiles,
        },
        width: '70%',
        height: '70%',
      })
      .afterClosed();
  }

  openFilesDialog(files: UploadedFileModel[], title: string): Observable<UploadedFileModel[]> {
    return this._dialog
      .open(FilesDialogComponent, {
        data: {
          files,
          title,
        },
        width: '70%',
      })
      .afterClosed();
  }

  openFileGallery(
    files: UploadedFileModel[],
    selectedIndex: number,
    options: {
      removeDisabled: boolean;
    },
    onDelete?: (index: number) => void
  ): Observable<UploadedFileModel[]> {
    return this._dialog
      .open(FileGalleryComponent, {
        data: {
          files,
          selectedIndex,
          onDelete,
          options,
        },
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100vh',
        width: '100vw',
        panelClass: 'fieldos-full-screen-dialog',
      })
      .afterClosed();
  }

  openHTMLEditor(html: string, title: string): Observable<string> {
    return this._dialog
      .open(HtmlEditorDialogComponent, {
        data: {
          html,
          title,
        },
      })
      .afterClosed();
  }
}
