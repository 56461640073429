import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DateCellRendererParams } from './date-cell-renderer.models';

@Component({
  selector: 'app-date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DatePipe],
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
  protected value = signal<Date | null>(null);
  protected readonly showTime = signal(false);

  agInit(params: DateCellRendererParams): void {
    this._setValue(params);
  }

  refresh(params: DateCellRendererParams): boolean {
    this._setValue(params);
    this.showTime.set(params.showTime || false);
    return true;
  }

  private _setValue(params: DateCellRendererParams): void {
    this.showTime.set(params.showTime || false);

    if (params.value && typeof params.value === 'string') {
      this.value.set(new Date(params.value as string));
    }

    if (params.value && params.value instanceof Date) {
      this.value.set(params.value);
    }
  }
}
