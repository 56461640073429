import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FolderTreeFlatNode } from './folder-tree.models';

export const createFolderTreeDataSource = () => {
  const transformer = (
    node: FolderTreeFlatNode,
    level: number
  ): FolderTreeFlatNode => ({
    ...node,
    expandable: !!node.children && node.children.length > 0,
    name: node.name,
    level: level,
  });

  const treeControl = new FlatTreeControl<FolderTreeFlatNode>(
    (node) => node.level || 0,
    (node) => node.expandable || false
  );

  const treeFlattener = new MatTreeFlattener<
    FolderTreeFlatNode,
    FolderTreeFlatNode
  >(
    transformer,
    (node) => node.level || 0,
    (node) => node.expandable || false,
    (node) => node.children
  );

  const dataSource = new MatTreeFlatDataSource<
    FolderTreeFlatNode,
    FolderTreeFlatNode
  >(treeControl, treeFlattener);

  return { dataSource, treeControl };
};
