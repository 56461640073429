import { Pipe, PipeTransform } from '@angular/core';
import { FileModule } from '@fieldos/models';
import { getFileModuleOptions } from './get-file-module-options';

@Pipe({
  name: 'fileEntityName',
  standalone: true,
})
export class FileEntityNamePipe implements PipeTransform {
  private readonly _getFileEntityOptions = getFileModuleOptions();

  transform(entityId: number, module: FileModule) {
    const options = this._getFileEntityOptions(module);

    if (!options || !options[entityId]) {
      return 'common.n_a';
    }

    return options[entityId].name;
  }
}
