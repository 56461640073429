import { inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { EntityException } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';

interface RouteExceptionsState {
  isLoading: boolean;
}

export const RouteExceptionsStore = signalStore(
  {
    providedIn: 'root',
  },
  withState<RouteExceptionsState>({ isLoading: false }),
  withEntities<EntityException>(),
  withMethods(
    (store, http = inject(HttpService), toast = inject(ToastStore)) => ({
      async fetchRouteExceptions() {
        try {
          patchState(store, { isLoading: true });

          const exceptions =
            await http.get<EntityException[]>(`/routes-exceptions`);

          patchState(store, setAllEntities(exceptions));
        } catch (e) {
          toast.showErrorToast('exceptions.fetch_error');
        } finally {
          patchState(store, { isLoading: false });
        }
      },
    })
  )
);
