<h1 mat-dialog-title class="text-lg text-center font-medium">
  {{ 'files.add_files.dialog.title' | transloco }}
</h1>

<mat-dialog-content>
  @if (!isProcessing()) {
    <app-file-uploader
      [accept]="[
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv'
      ]"
      (filesChange)="onFilesChange($event)" />

    @if (filesToUpload().length > 0) {
      <div class="flex justify-center">
        <button mat-flat-button (click)="processFiles()" color="primary">
          {{ 'files.process' | transloco }}
        </button>
      </div>
    }
  } @else {
    <app-process-uploaded-files
      [entityId]="data.entityId"
      [folderId]="data.folderId"
      [files]="uploadedFiles()"
      [module]="data.module"
      (valueChange)="onProcessedFilesChange($event)" />
  }
</mat-dialog-content>
<mat-dialog-actions class="border-t" align="end">
  <button mat-button mat-dialog-close>
    {{ 'common.close' | transloco }}
  </button>

  <button
    mat-flat-button
    [disabled]="!canSave()"
    (click)="saveFiles()"
    color="primary">
    {{ 'files.save' | transloco }}
  </button>
</mat-dialog-actions>
