import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DialogService, FileUploadFacade } from '@fieldos/core';
import { DocumentsSection, UploadedFileModel, MimeType } from '@fieldos/models';
import { minLengthArrayValidator } from '@fieldos/utils';
import { tap } from 'rxjs';
import { FileGalleryComponent } from '../../../../../@components/file-gallery';
import { FileUploaderComponent } from '../../../../../@components/file-uploader';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';
import { allowedMimeTypes } from './allowed-mime-types.provider';

@Component({
  selector: 'app-sections-form-upload-files-section',
  templateUrl: './sections-form-upload-files-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FileUploaderComponent, FileGalleryComponent],
})
export class SectionsFormUploadFilesSectionComponent
  extends SectionsFormSectionBaseComponent<DocumentsSection>
  implements OnInit
{
  protected accept: MimeType[] = [];
  protected progress: Record<string, number> = {};

  protected get files(): UploadedFileModel[] {
    return (this.form.value as UploadedFileModel[]) || [];
  }

  private readonly _dialogService = inject(DialogService);
  private readonly _fileFacade = inject(FileUploadFacade);
  private readonly _extensionMimeTypeMap = allowedMimeTypes();

  override ngOnInit(): void {
    super.ngOnInit();

    this.accept = this._extensionMimeTypeMap[this.section().subtype];
  }

  protected override initializeForm(): void {
    this.form = new FormControl<UploadedFileModel[]>([], { nonNullable: true });
  }

  protected onFilesAdded(files: UploadedFileModel[]): void {
    files.forEach((file) => {
      this._fileFacade
        .uploadSectionFileWithProgress(
          file.file as File,
          this.section().id,
          this.section().subtype
        )
        .pipe(
          tap((event) => {
            if (typeof event === 'number') {
              this.progress = {
                ...this.progress,
                [file.url]: event,
              };
            } else {
              delete this.progress[file.url];

              const existingFiles = this.form.value || [];
              this.form.setValue([...existingFiles, event]);
            }

            this.detector.detectChanges();
          })
        )
        .subscribe();
    });
  }

  protected onRemoveFile(index: number): void {
    const files = this.form.value;
    if (files) {
      files.splice(index, 1);
      this.form.setValue([...files]);
    }
  }

  protected onFileClick(index: number): void {
    this._dialogService.openFileGallery(
      this.files.reverse(),
      index,
      {
        removeDisabled: true,
      },
      this._onDelete
    );
  }

  protected override setValidation(): void {
    this.form.setValidators(minLengthArrayValidator(1));
    this.form.updateValueAndValidity();
  }

  private readonly _onDelete = (index: number): void => {
    const files = this.form.value || [];
    files.splice(index, 1);
    this.form.setValue([...files]);
    this.detector.detectChanges();
  };
}
