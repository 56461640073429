import { inject } from '@angular/core';
import { DomainEntityBase, FileModule, SectionSubType } from '@fieldos/models';
import {
  DataSourceStore,
  StandardDataSourceId,
} from '@fieldos/store/data-source-cache';
import { ServiceRequestStore } from '@fieldos/store/service-request.store';
import { WorkOrderStore } from '@fieldos/store/work-order.store';

export const getFileModuleOptions = () => {
  const dataSourceStore = inject(DataSourceStore);
  const workOrderStore = inject(WorkOrderStore);
  const serviceRequestStore = inject(ServiceRequestStore);

  if (workOrderStore.entities().length === 0) {
    workOrderStore.fetchAll();
  }

  if (serviceRequestStore.entities().length === 0) {
    serviceRequestStore.fetchAll();
  }

  return (
    moduleId: FileModule
  ): Record<number, DomainEntityBase<number>> | null => {
    switch (moduleId) {
      case FileModule.Assets:
        return dataSourceStore.selectNormalizedDataSource(
          StandardDataSourceId.Assets
        )();
      case FileModule.Contractors:
        return dataSourceStore.selectNormalizedDataSource(
          StandardDataSourceId.Contractors
        )();

      case FileModule.Customers:
        return dataSourceStore.selectNormalizedDataSource(
          StandardDataSourceId.Customers
        )();
      case FileModule.Locations:
        return dataSourceStore.selectNormalizedDataSource(
          StandardDataSourceId.Locations
        )();
      case FileModule.Parts:
        return dataSourceStore.selectNormalizedDataSource(
          StandardDataSourceId.Parts
        )();

      case FileModule.Users:
        return dataSourceStore.selectNormalizedDataSource(
          StandardDataSourceId.Users
        )();

      case FileModule.ServiceRequests:
        const serviceRequestSections = serviceRequestStore.sections();
        const serviceRequests = serviceRequestStore.entities();

        return serviceRequests.reduce((acc, serviceRequest) => {
          const titleSection = serviceRequestSections[
            serviceRequest.scopeId
          ].sections.find(
            (section) => section.subtype === SectionSubType.Title
          );

          const name =
            serviceRequest.value.find((v) => v.id === titleSection?.id)
              ?.value || '';

          return {
            ...acc,
            [serviceRequest.id]: {
              id: serviceRequest.id,
              name,
            },
          };
        }, {});

      case FileModule.WorkOrders:
        const sections = workOrderStore.sections();
        const workOrders = workOrderStore.entities();

        return workOrders.reduce((acc, workOrder) => {
          const titleSection = sections[workOrder.scopeId].sections.find(
            (section) => section.subtype === SectionSubType.Title
          );

          const name =
            workOrder.value.find((v) => v.id === titleSection?.id)?.value || '';

          return {
            ...acc,
            [workOrder.id]: {
              id: workOrder.id,
              name,
            },
          };
        }, {});

      default:
        return null;
    }
  };
};
