<div class="w-full h-full">
  @if (fileListItems().length) {
    <app-data-grid
      gridClass="h-full"
      [columns]="columns"
      [data]="fileListItems()" />
  } @else {
    <div class="w-full h-full flex items-center justify-center">
      <div class="flex flex-col gap-2">
        <span> No files found in this folder. </span>
        <button mat-flat-button color="primary">
          {{ 'files.add_file' | transloco }}
        </button>
      </div>
    </div>
  }
</div>
