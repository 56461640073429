import { Signal, effect } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadedFileModel, ReasonCodeAction } from '@fieldos/models';
import { minLengthArrayValidator } from '@fieldos/utils';
import { ExceptionTargetType } from './assign-exception.models';

export type DPUFormGroup = FormGroup<{
  dpuIds: FormControl<number[]>;
}>;

export type LocationFormGroup = FormGroup<{
  locationId: FormControl<number>;
}>;

export type SealsFormGroup = FormGroup<{
  seals: FormControl<string[]>;
}>;

type ValueFormGroup =
  | DPUFormGroup
  | LocationFormGroup
  | SealsFormGroup
  | FormControl<null>;

type ReasonCodeFormType = {
  taxonomyId: FormControl<number | null>;
  targetId: FormControl<number>;
  targetType: FormControl<ExceptionTargetType | null>;
  comments: FormControl<string>;
  media: FormControl<UploadedFileModel[]>;
  parentId: FormControl<number | null>;
  actionValue: ValueFormGroup;
};

export const createAssignReasonCodeForm = (
  reasonCodeAction: Signal<ReasonCodeAction | null>
) => {
  const form = new FormGroup<ReasonCodeFormType>({
    taxonomyId: new FormControl<number | null>(null, {
      validators: Validators.required,
    }),
    targetId: new FormControl(0, { nonNullable: true }),
    targetType: new FormControl<ExceptionTargetType | null>(null),
    comments: new FormControl('', { nonNullable: true }),
    media: new FormControl<UploadedFileModel[]>([], { nonNullable: true }),
    parentId: new FormControl<number | null>(null),
    actionValue: new FormControl(null),
  });

  effect(
    () => {
      const action = reasonCodeAction();

      form.patchValue({
        comments: '',
        media: [],
      });

      switch (action) {
        case 'changedestinationlocationinwo':
          return form.setControl(
            'actionValue',
            new FormGroup({
              locationId: new FormControl(null, {
                validators: [Validators.required],
                nonNullable: true,
              }),
            } as any)
          );
        case 'changeDPUandUnlockDPUSection':
          return form.setControl(
            'actionValue',
            new FormGroup({
              dpuIds: new FormControl<number[]>([], {
                validators: minLengthArrayValidator(1),
                nonNullable: true,
              }),
            })
          ) as any;
        case 'changeseal':
          return form.setControl(
            'actionValue',
            new FormGroup({
              seals: new FormControl([], {
                validators: minLengthArrayValidator(1),
              }),
            }) as any
          );
        case 'changestatusofwo':
          return form.setControl('actionValue', new FormControl(null));
        default:
          return form.setControl('actionValue', new FormControl(null));
      }
    },
    { allowSignalWrites: true }
  );

  return form;
};
