import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FileUploaderComponent } from '@fieldos/components';
import { UploadedFileModel } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { FilesDialogData } from './files-dialog.models';

@Component({
  selector: 'app-files-dialog',
  templateUrl: './files-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    FileUploaderComponent,
    MatButtonModule,
    TranslocoModule,
  ],
})
export class FilesDialogComponent {
  constructor() {
    this.files.set(this.data.files);
  }

  protected readonly data = inject<FilesDialogData>(MAT_DIALOG_DATA);

  protected readonly files = signal<UploadedFileModel[]>([]);
}
