<div class="flex flex-col items-center">
  @if (workOrdersToTransfer().length === 0) {
    <button mat-flat-button (click)="onTransferWorkOrders()" color="primary">
      {{ 'exceptions.action.transfer_work_orders.select' | transloco }}
    </button>
  } @else {
    <button mat-flat-button (click)="onTransferWorkOrders()" color="primary">
      {{ 'exceptions.action.transfer_work_orders' | transloco }} ({{
        workOrdersToTransfer().length
      }})
    </button>
  }
</div>
