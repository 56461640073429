import { effect, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { HttpService } from '@fieldos/core';
import { ScopeSectionsResponse, ServiceRequest } from '@fieldos/models';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  removeAllEntities,
  setAllEntities,
  withEntities,
} from '@ngrx/signals/entities';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '.';

interface ServiceRequestState {
  sections: Record<number, ScopeSectionsResponse>;
}

export const ServiceRequestStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<ServiceRequest>(),
  withState<ServiceRequestState>({
    sections: {},
  }),
  withMethods((store, http = inject(HttpService)) => ({
    async fetchAll(): Promise<void> {
      try {
        const workOrders =
          await http.get<ServiceRequest[]>('/service-requests');
        patchState(store, setAllEntities(workOrders));
      } catch (error) {
        console.error('Error fetching work orders', error);
      }
    },
    async fetchSections(): Promise<void> {
      try {
        const sections = await http.get<Record<number, ScopeSectionsResponse>>(
          '/sr-scopes-sections'
        );

        patchState(store, { sections });
      } catch (error) {
        console.error('Error fetching work orders', error);
      }
    },
  })),
  withHooks({
    onInit: async (store, authStore = inject(AuthStore)) => {
      effect(
        () => {
          const language = authStore.language();

          if (language) {
            patchState(store, removeAllEntities());
          }
        },
        { allowSignalWrites: true }
      );

      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => {
            store.fetchAll();
            store.fetchSections();
          }),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
