<div class="flex flex-col">
  <table mat-table class="w-full" [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>{{ 'common.name' | transloco }}</th>
      <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="module">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'files.module_name' | transloco }}
      </th>
      <td *matCellDef="let element; let index = index" mat-cell>
        <app-select-field
          [formControl]="filesFormArray.controls[index].controls.module"
          [options]="fileModules" />
      </td>
    </ng-container>

    <ng-container matColumnDef="folderName">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'files.folder' | transloco }}
      </th>
      <td *matCellDef="let element; let index = index" mat-cell>
        <div class="flex items-center">
          <mat-icon
            class="cursor-pointer scale-75"
            (click)="editFileFolder(index)">
            edit
          </mat-icon>

          {{
            filesFormArray.controls[index].controls.folderId.value
              | folderName
              | transloco
          }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="entityName">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'files.entity_name' | transloco }}
      </th>
      <td *matCellDef="let element; let index = index" mat-cell>
        <div class="flex">
          <mat-icon
            class="cursor-pointer scale-75"
            (click)="editEntityId(index)">
            edit
          </mat-icon>
          {{
            filesFormArray.controls[index].controls.entityId.value
              | fileEntityName
                : filesFormArray.controls[index].controls.module.value
              | transloco
          }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="issueDate">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'common.issue_date' | transloco }}
      </th>
      <td *matCellDef="let element; let index = index" mat-cell>
        <app-datetime-picker
          hideClear
          [formControl]="filesFormArray.controls[index].controls.issueDate"
          [useTime]="false" />
      </td>
    </ng-container>

    <ng-container matColumnDef="expiryDate">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'common.expiry_date' | transloco }}
      </th>
      <td *matCellDef="let element; let index = index" mat-cell>
        <app-datetime-picker
          hideClear
          [formControl]="filesFormArray.controls[index].controls.issueDate"
          [useTime]="false" />
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'common.tags' | transloco }}
      </th>
      <td *matCellDef="let element" mat-cell>
        <div class="flex">
          <mat-icon class="cursor-pointer scale-75"> edit </mat-icon>
          {{ element.tags }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="isLoading">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'common.status' | transloco }}
      </th>
      <td *matCellDef="let element" mat-cell>
        <div class="flex w-full justify-center">
          @if (!element.id) {
            <mat-spinner diameter="20" />
          } @else {
            <mat-icon
              matTooltip="{{ 'files.uploaded' | transloco }}"
              class="cursor-pointer"
              color="success">
              check_circle
            </mat-icon>
          }
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns" mat-row></tr>
  </table>
</div>
