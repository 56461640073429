import { Pipe, PipeTransform } from '@angular/core';
import { fileFolderNameMapper } from './file-folder-name.mapper';

@Pipe({
  name: 'folderName',
  standalone: true,
})
export class FolderNamePipe implements PipeTransform {
  private readonly _mapper = fileFolderNameMapper();

  transform(folderId: number): string {
    return this._mapper(folderId);
  }
}
