<mat-form-field
  #trigger="cdkOverlayOrigin"
  class="fuse-mat-dense w-full"
  cdkOverlayOrigin
  subscriptSizing="dynamic">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }

  <input
    matInput
    [disabled]="disabled || isDisabled"
    [value]="formattedValue"
    (click)="openMenu(); $event.stopPropagation()"
    readonly />

  <div matSuffix class="flex-shrink-0">
    @if (!disabled && !isDisabled && formattedValue && !hideClear) {
      <button mat-icon-button (click)="clear()" appClickStopPropagation>
        <mat-icon svgIcon="mat_solid:clear" />
      </button>
    }

    <button
      mat-icon-button
      [disabled]="disabled || isDisabled"
      (click)="menuOpen = !menuOpen"
      appClickStopPropagation>
      <mat-icon>calendar_month</mat-icon>
    </button>
  </div>
</mat-form-field>

<ng-template
  (overlayOutsideClick)="$event.stopPropagation()"
  [cdkConnectedOverlayPanelClass]="'ag-custom-component-popup'"
  [cdkConnectedOverlayOpen]="menuOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  (backdropClick)="$event.stopPropagation()"
  (overlayKeydown)="$event.stopPropagation()"
  cdkConnectedOverlay>
  <div class="w-80">
    <div
      (clickOutside)="closeMenu()"
      class="flex justify-center w-full bg-white z-99999 shadow-md">
      <div class="flex flex-col w-80">
        @if (showTime) {
          <div class="flex gap-2 items-end p-2">
            <mat-form-field class="fuse-mat-dense" subscriptSizing="dynamic">
              <mat-label>
                {{ 'common.hours' | transloco }}
              </mat-label>
              <input
                matInput
                [formControl]="hoursCtrl"
                max="24"
                min="0"
                type="number" />
            </mat-form-field>

            <mat-form-field class="fuse-mat-dense" subscriptSizing="dynamic">
              <mat-label>
                {{ 'common.minutes' | transloco }}
              </mat-label>
              <input
                matInput
                [formControl]="minutesCtrl"
                max="59"
                min="0"
                type="number" />
            </mat-form-field>
          </div>
        }

        <mat-calendar
          class="border"
          [(selected)]="localValue"
          (selectedChange)="onSelectionChange($event)" />

        <div class="w-full flex justify-between p-2 border">
          @if (useTime) {
            <button mat-icon-button (click)="showTime = !showTime">
              <mat-icon svgIcon="mat_solid:access_time" />
            </button>
          }
          <button mat-flat-button (click)="onSave()" color="primary">
            {{ 'common.save' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
