import { TranslatableDomainEntityBase } from '../entity-base';
import { UploadedFileModel } from '../file';
import { RightsStatusType } from '../sections';
import { ChecklistTaskType } from './checklist-task-type';

export interface ChecklistTaskValueBase<TValue = unknown> {
  executed: boolean;
  value: TValue;
}

export interface ChecklistTask<
  TChecklistValue = unknown,
  TConditionalsType = unknown,
> extends TranslatableDomainEntityBase<string> {
  type: ChecklistTaskType;
  mandatory: boolean;
  useConditionals: boolean;
  defaultValue: ChecklistTaskValueBase<TChecklistValue>['value'];
  imagesRights: RightsStatusType;
  filesRights: RightsStatusType;
  notesRights: RightsStatusType;
  files: UploadedFileModel[];
  link: string;
  notes: string;
  conditionals?: TConditionalsType;
}

export interface ChecklistSection extends TranslatableDomainEntityBase<string> {
  tasks: ChecklistTask[];
  type: 'section';
}

export type ChecklistTaskConditionActionTask =
  | 'uploadfileonlocation'
  | 'writeanote';
