import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExceptionDialogsFacade } from '@fieldos/business';
import { ExceptionsFacade } from '@fieldos/facades';
import { EntityException } from '@fieldos/models';
import { WorkspaceStore } from '@fieldos/store/workspace.store';
import { TranslocoModule } from '@ngneat/transloco';
import { RouteExceptionsStore } from './route-exceptions.store';

@Component({
  selector: 'app-route-exceptions',
  templateUrl: './route-exceptions.component.html',
  standalone: true,
  imports: [MatIconModule, TranslocoModule, MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [':host { display: contents; }'],
})
export class RouteExceptionsComponent {
  protected readonly store = inject(RouteExceptionsStore);

  private readonly _exceptionDialogFacade = inject(ExceptionDialogsFacade);
  private readonly _facade = inject(ExceptionsFacade);
  private readonly _scopeId = inject(WorkspaceStore).selectedWorkspaceScopeId;

  protected async onInfoClick(exception: EntityException): Promise<void> {
    this._exceptionDialogFacade.openInfoDialog(exception);
  }

  protected async onActionClick(exception: EntityException): Promise<void> {
    try {
      const assignedException = await this._facade.fetchException(exception.id);

      await this._exceptionDialogFacade.openDialog({
        targetId: assignedException.targetId,
        targetType: exception.targetType,
        parentId: exception.id,
        reasonCodeName: exception.label,
        scopeId: this._scopeId(),
      });
    } catch (e) {
      console.error(e);
    }
  }
}
