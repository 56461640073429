import { DomainEntityBase } from '../entity-base';
import { FileModule } from './file-module';
import { MimeType } from './mime-type';

export interface FileModel extends DomainEntityBase {
  fileId: string;
  nameOnDisk: string;
  size: number;
  mimeType: MimeType;
  url: string;
  thumbnailUrl: string;
  moduleId: FileModule;
  entityId: number;
  tagIds: number[];
  categoryId: number;
  issueDate: string | null;
  expiryDate: string | null;
}
