import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { DialogService } from '@fieldos/core';
import { Folder } from '@fieldos/models';
import { FolderStore } from '@fieldos/store/files';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import { FolderTreeFlatNode } from './folder-tree.models';
import { createFolderTreeDataSource } from './folder-tree.utils';

@Component({
  selector: 'app-folder-tree',
  templateUrl: './folder-tree.component.html',
  standalone: true,
  imports: [MatIconModule, MatTreeModule, TranslocoModule, MatDividerModule],
})
export class FolderTreeComponent implements AfterViewInit {
  constructor() {
    effect(() => {
      const folders = this.store.entities();
      const folderMap = this.store.entityMap();

      const rootFolder = folderMap[0];

      const buildTree = (folder: Folder): FolderTreeFlatNode => ({
        ...folder,
        children: folders
          .filter((f) => f.parentId === folder.id)
          .map(buildTree),
      });

      const tree = buildTree(rootFolder);

      const selection = this.treeUtils.treeControl.expansionModel.selected;

      this.treeUtils.dataSource.data = [tree];
      this.treeUtils.treeControl.collapseAll();

      selection.forEach((n) => {
        const node = this.treeUtils.treeControl.dataNodes.find(
          (e) => e.id === n.id
        );

        if (node) {
          this.treeUtils.treeControl.expand(node);
        }
      });

      if (this.defaultExpanded()) {
        this.treeUtils.treeControl.expandAll();
      }
    });
  }

  public readonly selectFolder = output<number>();
  public readonly defaultExpanded = input(false, {
    transform: booleanAttribute,
  });

  public readonly selectedFolderId = input.required<number>();
  public readonly hideCreateButton = input(false, {
    transform: booleanAttribute,
  });

  protected readonly treeUtils = createFolderTreeDataSource();
  protected readonly store = inject(FolderStore);

  private readonly _dialog = inject(DialogService);
  private readonly _detector = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    const selectedFolderId = this.selectedFolderId();

    if (selectedFolderId > -1) {
      setTimeout(() => {
        let folder = this.store.entityMap()[selectedFolderId];

        while (folder) {
          const node = this.treeUtils.treeControl.dataNodes.find(
            (e) => e.id === selectedFolderId
          );

          if (node) {
            this.treeUtils.treeControl.expand(node);
            folder = this.store.entityMap()[folder.parentId];
          }
        }

        this._detector.detectChanges();
      });
    } else {
      setTimeout(() => {
        const node = this.treeUtils.treeControl.dataNodes.find(
          (e) => e.id === 0
        );

        if (node) {
          this.treeUtils.treeControl.expand(node);
        }

        this._detector.detectChanges();
      });
    }
  }

  protected hasChild = (_: number, node: FolderTreeFlatNode) => node.expandable;

  protected async openAddFolder(parentId: number) {
    const folderName = await lastValueFrom(
      this._dialog.openTextFieldDialog('files.add_folder', 'files.folder_name')
    );

    if (folderName) {
      const newFolder = await this.store.createFolder(folderName, parentId);

      if (newFolder) {
        let parentFoder = this.store.entityMap()[parentId];
        const foldersToExpand = [];

        while (parentFoder) {
          foldersToExpand.push(parentFoder.id);
          parentFoder = this.store.entityMap()[parentFoder.parentId];
        }

        foldersToExpand.forEach((folderId) => {
          const treeNode = this.treeUtils.treeControl.dataNodes.find(
            (e) => e.id === folderId
          );

          if (treeNode) {
            this.treeUtils.treeControl.expand(treeNode);
          }
        });
      }
    }
  }

  protected async openRemoveFolder(folderId: number): Promise<void> {
    const canDelete = await lastValueFrom(this._dialog.showConfirmDialog());

    if (canDelete) {
      await this.store.deleteFolder(folderId);
    }
  }
}
