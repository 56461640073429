import { HttpErrorResponse } from '@angular/common/http';
import { computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { HttpService } from '@fieldos/core';
import { ErrorResponse, Folder } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  setAllEntities,
  setEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '..';

interface FolderStoreStae {
  loading: boolean;
  selectedFolderId: number;
}

export const FolderStore = signalStore(
  { providedIn: 'root' },
  withEntities<Folder>(),
  withState<FolderStoreStae>({
    loading: false,
    selectedFolderId: 0,
  }),
  withMethods(
    (store, http = inject(HttpService), toast = inject(ToastStore)) => ({
      async fetchFolders() {
        patchState(store, { loading: true });
        try {
          const folders = await http.get<Folder[]>('/file-categories');
          patchState(
            store,
            setAllEntities([
              { id: 0, name: 'files.all_files', parentId: -1 },
              ...folders,
            ])
          );
        } catch (error) {
          console.error(error);
        } finally {
          patchState(store, { loading: false });
        }
      },
      async createFolder(
        name: string,
        parentId: number
      ): Promise<Folder | undefined> {
        try {
          const folder = await http.post<Folder>('/file-categories', {
            name,
            parentId,
          });

          patchState(store, setEntity(folder));

          toast.showSuccessToast('files.create_folder.success');
          return folder;
        } catch (error) {
          const err = error as HttpErrorResponse;

          const errorMessage = err.error as ErrorResponse;

          if (errorMessage.error.message) {
            toast.showErrorToast(errorMessage.error.message);
            return;
          }

          toast.showErrorToast('files.create_folder.error');
          return;
        }
      },
      async updateFolder(name: string): Promise<void> {
        try {
          const folderId = store.selectedFolderId();
          const folder = store.entities().find((f) => f.id === folderId);

          if (!folder) {
            return;
          }

          await http.put(`/file-categories/${folderId}`, {
            name,
            parentId: folder.parentId,
          });

          patchState(store, setEntity({ ...folder, name }));

          toast.showSuccessToast('files.update_folder.success');
        } catch (error) {
          toast.showErrorToast('files.update_folder.error');
        }
      },
      async deleteFolder(folderId: number): Promise<void> {
        try {
          await http.delete(`/file-categories/${folderId}`);
          patchState(
            store,
            setAllEntities(
              store.entities().filter((folder) => folder.id !== folderId)
            )
          );

          toast.showSuccessToast('files.delete_folder.success');
        } catch (error) {
          toast.showErrorToast('files.delete_folder.error');
        }
      },
      async deleteSelectedFolder(): Promise<void> {
        try {
          const folderId = store.selectedFolderId();
          await http.delete(`/file-categories/${folderId}`);
          patchState(
            store,
            setAllEntities(
              store.entities().filter((folder) => folder.id !== folderId)
            )
          );

          toast.showSuccessToast('files.delete_folder.success');
        } catch (error) {
          toast.showErrorToast('files.delete_folder.error');
        }
      },
      setSelectedFolder(folderId: number): void {
        patchState(store, { selectedFolderId: folderId });
      },
    })
  ),
  withComputed((store) => ({
    selectedFolderHasChildren: computed(() => {
      const selectedFolderId = store.selectedFolderId();
      return store
        .entities()
        .some((folder) => folder.parentId === selectedFolderId);
    }),
    selectedFolder: computed(() =>
      store.entities().find((folder) => folder.id === store.selectedFolderId())
    ),
  })),
  withHooks({
    onInit: async (store, authStore = inject(AuthStore)) => {
      effect(
        () => {
          const language = authStore.language();

          if (language) {
            store.fetchFolders();
          }
        },
        { allowSignalWrites: true }
      );

      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.fetchFolders()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
