<h1 mat-dialog-title>
  {{ 'files.select_file_entity_dialog.title' | transloco }}
</h1>
<mat-dialog-content>
  @if (options(); as entityOptions) {
    <app-select-field [formControl]="formCtrl" [options]="entityOptions" />
  } @else {
    <div class="text-center font-bold">
      {{ 'files.select_file_entity_dialog.not_available' | transloco }}
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-dialog-close mat-flat-button>
    {{ 'common.cancel' | transloco }}
  </button>

  <button
    mat-flat-button
    [disabled]="formCtrl.invalid"
    [mat-dialog-close]="formCtrl.value"
    color="primary">
    {{ 'common.save' | transloco }}
  </button>
</mat-dialog-actions>
