import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DialogService } from '@fieldos/core';
import { WorkOrderAssigneeLocationWorkOrder } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-assign-exception-transfer-work-orders-action',
  templateUrl: './assign-exception-transfer-work-orders-action.component.html',
  standalone: true,
  imports: [MatButtonModule, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignExceptionTransferWorkOrdersActionComponent {
  public readonly exceptionId = input.required<number>();

  protected readonly workOrdersToTransfer = signal<
    WorkOrderAssigneeLocationWorkOrder[]
  >([]);

  private readonly _dialog = inject(DialogService);

  protected async onTransferWorkOrders(): Promise<void> {
    const component = await import(
      './transfer-route-work-orders-dialog/transfer-route-work-orders-dialog.component'
    ).then((m) => m.TransferRouteWorkOrdersDialogComponent);

    const workOrdersToTransfer = await lastValueFrom(
      this._dialog.openDialog<number, WorkOrderAssigneeLocationWorkOrder[]>(
        component,
        {
          autoFocus: false,
          data: this.exceptionId(),
          width: '900px',
          minWidth: '900px',
          maxHeight: '60vh',
        }
      )
    );

    if (workOrdersToTransfer) {
      this.workOrdersToTransfer.set(workOrdersToTransfer);
    }
  }
}
