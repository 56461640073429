import { UploadedFileModel } from './file';

export interface ChatMessage {
  id: number;
  userId: number;
  date: string;
  dateDiff: string;
  message: string;
  userAvatar: string | null;
  userName: string;
  files: UploadedFileModel[];
}
