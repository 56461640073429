import { Folder } from '@fieldos/models';

export const isSubFolder = (
  folderId: number,
  mainFolderId: number,
  folderMap: Record<number, Folder>
): boolean => {
  if (folderId === mainFolderId) {
    return true;
  }

  while (folderId !== -1) {
    const folder = folderMap[folderId];

    if (folder) {
      folderId = folder.parentId;
      if (folderId === mainFolderId) {
        return true;
      }
    } else {
      break;
    }
  }

  return false;
};
