import { MimeType } from './mime-type';

export interface UploadedFileModel {
  id: string;
  size: number;
  mimeType: MimeType;
  description: string;
  thumbnailUrl: string;
  url: string;
  name: string;
  file?: File;
  value?: string;
}

export interface FileUploadResponse {
  description: string;
  id: string;
  mimeType: MimeType;
  size: number;
  thumbnailUrl: string;
  url: string;
  value?: string;
}
