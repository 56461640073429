import { ChatMessage, UploadedFileModel } from '@fieldos/models';

export interface ChatMessageItem extends ChatMessage {
  isMine: boolean;
}

export interface SendChatMessageModel {
  message: string;
  files: UploadedFileModel[];
}
