import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  ChecklistTaskValueChangeEvent,
  ChecklistViewerComponent,
} from '@fieldos/business';
import { MultipleSelectComponent } from '@fieldos/components';
import { WorkOrderChecklistsFacade } from '@fieldos/facades';
import { Checklist, Section } from '@fieldos/models';
import { ChecklistStore } from '@fieldos/store/checklists.store';
import { TranslocoService } from '@ngneat/transloco';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-checklists-section',
  templateUrl: './sections-form-checklists-section.component.html',
  standalone: true,
  imports: [
    MultipleSelectComponent,
    ReactiveFormsModule,
    ChecklistViewerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsFormChecklistsSectionComponent extends SectionsFormSectionBaseComponent<
  Section<number[]>
> {
  constructor() {
    super();

    effect(
      async () => {
        const entity = this.store.entity();
        const selectedChecklistId = this.selectedChecklistId();

        if (entity && selectedChecklistId) {
          const data = await this._facade.fetchChecklists(entity.id);
          if (!data) {
            return;
          }

          const checklist = data.find(
            (e) => e.checklistId === selectedChecklistId
          );

          const taskValueMap = checklist?.tasks.reduce(
            (acc, current) => ({
              ...acc,
              [current.taskId]: current.value,
            }),
            {} as Record<string, unknown>
          );

          this.taskValueMap.set(taskValueMap || {});
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        const options = this.options();
        const selectedChecklistIds = this.form.getRawValue() as number[];

        if (options && options.length > 0 && selectedChecklistIds.length > 0) {
          this.selectedChecklistId.set(selectedChecklistIds[0]);
          this.onChecklistSelected(selectedChecklistIds[0]);
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly options = computed(() =>
    this._checklists().map((checklist) => ({
      id: checklist.id,
      name: checklist.translatableProperties[this._activeLanguage()].name,
    }))
  );

  protected readonly selectedChecklistId = signal<number>(0);
  protected readonly selectedChecklist = signal<Checklist | null>(null);
  protected readonly taskValueMap = signal<Record<string, unknown>>({});

  private readonly _checklists = inject(ChecklistStore).entities;
  private readonly _store = inject(ChecklistStore);
  private readonly _facade = inject(WorkOrderChecklistsFacade);

  private readonly _activeLanguage = toSignal(
    inject(TranslocoService).langChanges$
  ) as Signal<string>;

  protected override initializeForm(): void {
    this.form = new FormControl<number[]>([], { nonNullable: true });
  }

  protected onChecklistSelected(option: number): void {
    this.selectedChecklistId.set(option);
    this.selectedChecklist.set(this._store.entityMap()[option] as Checklist);
  }

  protected onTaskChange(
    event: ChecklistTaskValueChangeEvent<unknown>
  ): Promise<void> {
    const entity = this.store.entity();

    if (!entity) {
      return Promise.resolve();
    }

    return this._facade.saveChecklistTaskValue(
      entity.id,
      this.selectedChecklistId(),
      event.task.id,
      event.task.type,
      event.value
    );
  }
}
