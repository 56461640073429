import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { FolderTreeComponent } from '../../folder-tree/folder-tree.component';

@Component({
  selector: 'app-select-folder-dialog',
  templateUrl: './select-folder-dialog.component.html',
  standalone: true,
  imports: [
    FolderTreeComponent,
    MatDialogModule,
    MatButtonModule,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFolderDialogComponent {
  constructor() {
    const selectedFolderId = inject<number>(MAT_DIALOG_DATA);

    this.selectedFolderId.set(selectedFolderId || 0);
  }

  protected readonly selectedFolderId = signal<number>(0);
}
