import { Section } from './section';

export interface SectionsResponse {
  id: string;
  timestamp: number;
  sections: Section[];
}

export interface ScopeSectionsResponse {
  timestamp: number;
  sections: Section[];
}
