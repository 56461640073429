import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { DataGridActionsService, DataGridComponent } from '@fieldos/components';
import { FileModel } from '@fieldos/models';
import { FileEntityNamePipe } from '@fieldos/pipes';
import { FolderStore } from '@fieldos/store/files';
import { WorkOrderStore } from '@fieldos/store/work-order.store';
import { fromServerDate } from '@fieldos/utils';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { isSubFolder } from '../file.utils';
import { createFolderFileColumns } from './folder-files.columns';
import { FileRowItem } from './folder-files.models';

@Component({
  selector: 'app-folder-files',
  templateUrl: './folder-files.component.html',
  standalone: true,
  imports: [DataGridComponent, TranslocoModule, MatButtonModule],
  providers: [DataGridActionsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      display: contents;
    }
  `,
})
export class FolderFilesComponent {
  public readonly files = input.required<FileModel[]>();
  public readonly selectedFolderId = input.required<number>();

  protected readonly fileListItems = computed(() => {
    const selectedLang = this._language();
    const sections = this._workOrderStore.sections();
    const workOrders = this._workOrderStore.entities();

    return this.files()
      .filter((file) =>
        isSubFolder(
          file.categoryId,
          this.selectedFolderId(),
          this._folderStore.entityMap()
        )
      )
      .map((file) => {
        const folder = this._folderStore.entityMap()[file.categoryId];

        return {
          entityName: this._transloco.translate(
            this._fileEntityNamePipe.transform(file.entityId, file.moduleId)
          ),
          expiryDate: fromServerDate(file.expiryDate),
          folderName: this._transloco.translate(folder?.name || ''),
          id: file.id,
          issueDate: fromServerDate(file.issueDate),
          moduleName: this._transloco.translate(
            `files.module.${file.moduleId}`
          ),
          name: file.name,
          tags: file.tagIds.join(', '),
        } as FileRowItem;
      });
  });

  protected readonly columns = createFolderFileColumns();

  private readonly _folderStore = inject(FolderStore);
  private readonly _language = toSignal(inject(TranslocoService).langChanges$);
  private readonly _transloco = inject(TranslocoService);
  private readonly _workOrderStore = inject(WorkOrderStore);
  private readonly _fileEntityNamePipe = new FileEntityNamePipe();
}
