import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DialogService, FileUploadFacade } from '@fieldos/core';
import { UploadedFileModel, SignatureSection } from '@fieldos/models';
import { filterEmpty, minLengthArrayValidator } from '@fieldos/utils';
import { of, switchMap, tap } from 'rxjs';
import { SignatureComponent } from '../../../../../@components/fields/signature';
import { FileUploaderComponent } from '../../../../../@components/file-uploader';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';
import { SectionsFormSignatureDialogComponent } from './sections-form-signature-dialog/sections-form-signature-dialog.component';

@Component({
  selector: 'app-sections-form-signature-section',
  templateUrl: './sections-form-signature-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SignatureComponent,
    SectionsFormSignatureDialogComponent,
    FileUploaderComponent,
  ],
})
export class SectionsFormSignatureSectionComponent extends SectionsFormSectionBaseComponent<SignatureSection> {
  protected progress: Record<string, number> = {};

  protected get files(): UploadedFileModel[] {
    return this.form.value as UploadedFileModel[];
  }

  private readonly _dialogService = inject(DialogService);
  private readonly _uploadService = inject(FileUploadFacade);

  protected override initializeForm(): void {
    this.form = new FormControl<UploadedFileModel[]>([], {
      nonNullable: true,
    });
  }

  protected openSignature(): void {
    this._dialogService
      .openDialog<void, UploadedFileModel>(SectionsFormSignatureDialogComponent)
      .pipe(
        filterEmpty(),
        switchMap((fileModel) => {
          this.progress[fileModel.url] = 0;

          if (!fileModel) {
            return of();
          }

          this.form.setValue([
            ...(this.form.getRawValue() as UploadedFileModel[]),
            fileModel,
          ]);

          return this._uploadService
            .uploadSectionFileWithProgress(
              fileModel.file as File,
              this.section().id,
              this.section().subtype,
              fileModel.value
            )
            .pipe(
              tap((event) => {
                if (typeof event === 'number') {
                  this.progress = {
                    ...this.progress,
                    [fileModel.url]: event,
                  };
                } else {
                  delete this.progress[fileModel.url];
                  const files = this.form.value as UploadedFileModel[];

                  const index = files.findIndex(
                    (e) => e?.url === fileModel.url
                  );

                  files.splice(index, 1, event);
                  this.form.setValue([...files]);
                }

                this.detector.detectChanges();
              })
            );
        })
      )
      .subscribe();
  }

  protected override setValidation(): void {
    this.form.setValidators(minLengthArrayValidator(1));
    this.form.updateValueAndValidity();
  }

  protected onFileClick(index: number): void {
    this._dialogService.openFileGallery(
      this.files,
      index,
      {
        removeDisabled: this.form.disabled,
      },
      this._onDelete
    );
  }

  protected onRemoveFile(index: number): void {
    const files = this.form.value;
    files?.splice(index, 1);

    this.form.setValue(files);
  }

  private readonly _onDelete = (index: number): void => {
    const files = this.form.value || [];
    files.splice(index, 1);
    this.form.setValue([...files]);
    this.detector.detectChanges();
  };
}
