import { InjectionToken } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { TextCellRendererComponent, TextFloatingFilterComponent } from './text';
import { TextFilterComponent } from './text/text-filter/text-filter.component';

export const DEFAULT_COLUMN = new InjectionToken('DEFAULT_COLUMN', {
  factory: (): ColDef => ({
    floatingFilter: true,
    cellRenderer: TextCellRendererComponent,
    filter: TextFilterComponent,
    floatingFilterComponent: TextFloatingFilterComponent,
    minWidth: 200,
    resizable: true,
    sortable: true,
    initialHide: false,
    enablePivot: true,
    autoHeight: false,
    comparator: (valueA, valueB) => {
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA
          .trim()
          .toLowerCase()
          .localeCompare(valueB.trim().toLowerCase());
      }

      return valueA - valueB;
    },
  }),
});
