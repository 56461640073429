import { Route } from '@angular/router';
import { ClaimsGuard } from '@fieldos/core';

export const workOrdersRoutes: Route[] = [
  {
    path: 'work-orders',
    loadComponent: () =>
      import('./work-orders/work-orders.component').then(
        (m) => m.WorkOrdersComponent
      ),
    data: {
      title: 'work_orders.list',
    },
  },
  {
    path: 'work-orders/locations',
    loadComponent: () =>
      import('./work-order-locations/work-order-locations.component').then(
        (m) => m.WorkOrderLocationsComponent
      ),
    data: {
      title: 'menu.work_order_locations',
    },
  },
  {
    path: 'work-orders/routes',
    canActivate: [ClaimsGuard],
    loadComponent: () =>
      import('./work-order-routes/work-order-routes.component').then(
        (m) => m.WorkOrderRoutesComponent
      ),
    data: {
      title: 'menu.routes',
      permissions: ['workorderslocation.route.list'],
    },
  },
  {
    path: 'work-orders/routes/exceptions',
    canActivate: [ClaimsGuard],
    loadComponent: () =>
      import(
        './work-order-locations/work-order-route-exceptions/work-order-route-exceptions.component'
      ).then((m) => m.WorkOrderRouteExceptionsComponent),
    data: {
      title: 'menu.routes',
      permissions: ['workorderslocation.route.exceptions.list'],
    },
  },
  {
    path: 'work-orders/dpus',
    canActivate: [ClaimsGuard],
    loadComponent: () =>
      import('./dpus/dpus.component').then((m) => m.DPUsComponent),
    data: {
      title: 'menu.dpus',
      permissions: ['workorderslocation.dpu.list'],
    },
  },
];
