import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DialogService } from '@fieldos/core';
import { UploadedFileModel } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-files-cell-renderer',
  templateUrl: './files-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, TranslocoModule],
})
export class FilesCellRendererComponent implements ICellRendererAngularComp {
  protected readonly value = signal<UploadedFileModel[]>([]);

  private readonly _dialogService = inject(DialogService);

  agInit(params: ICellRendererParams<string, UploadedFileModel[], unknown>): void {
    const value = params.value as UploadedFileModel[];
    this.value.set(value);
  }

  refresh(params: ICellRendererParams<string, UploadedFileModel[], unknown>): boolean {
    const value = params.value as UploadedFileModel[];
    this.value.set(value);
    return false;
  }

  protected openGallery(): void {
    this._dialogService
      .openFileGallery(this.value(), 0, {
        removeDisabled: true,
      })
      .subscribe();
  }
}
