export interface ReportResponse {
  header: ReportHeader[];
  data: ReportEntry[];
  reportStyles: ReportStyles;
}

export interface ReportHeader {
  name: string;
}

export type ReportEntry = Array<ReportColumn>;

export interface ReportColumn {
  value: string | null;
}

export interface ReportStyles {
  rowStyles: ReportRowStyle[];
}

export interface ReportRowStyle {
  rowIndexes: number[];
  backgroundColor?: string;
  color?: string;
}
