@if (store.isLoading()) {
  <div class="flex items-center justify-center w-full h-full">
    <mat-spinner color="primary" diameter="32" />
  </div>
} @else {
  <div class="h-full w-full">
    <div class="flex flex-col items-center text-base">
      @if (store.entities().length) {
        <div class="flex w-full gap-2">
          <span class="flex-1 font-bold">
            {{ 'exceptions.auto' | transloco }}
          </span>

          <span class="flex-1 font-bold">
            {{ 'exceptions.route' | transloco }}
          </span>

          <span class="flex-1 font-bold">
            {{ 'exceptions.title' | transloco }}
          </span>

          <span class="flex-1 font-bold">
            {{ 'exceptions.resolve_title' | transloco }}
          </span>

          <span class="flex-1 font-bold text-center">
            {{ 'common.actions' | transloco }}
          </span>
        </div>
      }

      @for (exception of store.entities(); track $index) {
        <div class="flex w-full py-1 border-b gap-2">
          <span class="flex-1 flex items-center leading-4 text-md">
            {{ exception.targetAssigneeNameSurname }}
          </span>

          <span class="flex-1 flex items-center leading-4 text-md">
            {{ exception.targetLabel }}
          </span>

          <span class="flex-1 flex items-center leading-4 text-md gap-1">
            <button mat-icon-button (click)="onInfoClick(exception)">
              <mat-icon>info_outline</mat-icon>
            </button>

            <div class="text-base">
              {{ exception.label }}
            </div>
          </span>

          <span class="flex-1 flex items-center text-md">
            {{ exception.labelConfirmed }}
          </span>

          <span class="flex-1 flex justify-center items-center">
            @if (exception.blocksWorkOrder) {
              <button mat-icon-button (click)="onActionClick(exception)">
                <mat-icon>published_with_changes</mat-icon>
              </button>
            }
          </span>
        </div>
      }
    </div>
  </div>
}
