<app-header-sidebar-content-layout hideToolbar sidebarClass="min-w-72">
  <div sidebar class="h-full min-w-72">
    <app-folder-tree
      [selectedFolderId]="selectedFolderId()"
      (selectFolder)="selectFolder.emit($event)" />
  </div>

  @if (selectedFolderId() > -1) {
    <div class="h-full w-full" content>
      <div
        class="border-b p-2 flex gap-2 items-center justify-end bg-card dark:bg-transparent w-full z-9999">
        <button
          mat-icon-button
          matTooltip="{{ 'files.edit_folder_name' | transloco }}"
          (click)="openDeleteFolderDialog()"
          color="primary">
          <mat-icon>edit</mat-icon>
        </button>

        <div
          matTooltip="{{
            folderStore.selectedFolderHasChildren()
              ? ('files.delete_folder.has_subfolders.tooltip' | transloco)
              : ''
          }}">
          <button
            mat-icon-button
            matTooltip="{{ 'files.delete_folder' | transloco }}"
            [disabled]="folderStore.selectedFolderHasChildren()"
            (click)="openEditFolderDialog()"
            color="warn">
            <mat-icon>delete</mat-icon>
          </button>
        </div>

        <button
          *appHasPermission="['files.add']"
          mat-icon-button
          matTooltip="{{ 'files.add_file' | transloco }}"
          (click)="addFile.emit()"
          color="primary">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>

      <app-folder-files
        [selectedFolderId]="folderStore.selectedFolderId()"
        [files]="files()" />
    </div>
  } @else {
    <div class="h-full w-full flex items-center justify-center" content>
      Select a folder to view its contents.
    </div>
  }
</app-header-sidebar-content-layout>
