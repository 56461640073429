import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
} from '@angular/core';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { HeaderContentLayoutComponent } from '../header-content-layout/header-content-layout.component';

@Component({
  selector: 'app-header-sidebar-content-layout',
  templateUrl: './header-sidebar-content-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, HeaderContentLayoutComponent, FuseScrollbarDirective],
})
export class HeaderSidebarContentLayoutComponent {
  public readonly hideToolbar = input(false, { transform: booleanAttribute });

  public readonly sidebarClass = input<string>('');
}
