import { inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { FileModel } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import {
  setAllEntities,
  setEntity,
  withEntities,
} from '@ngrx/signals/entities';

interface FileStoreState {
  loading: boolean;
}

export const FileStore = signalStore(
  { providedIn: 'root' },
  withEntities<FileModel>(),
  withState<FileStoreState>({
    loading: false,
  }),
  withMethods(
    (store, http = inject(HttpService), toast = inject(ToastStore)) => ({
      async fetchFiles(): Promise<void> {
        try {
          const files = await http.get<FileModel[]>('/files');
          patchState(store, setAllEntities(files));
        } catch (error) {
          toast.showErrorToast('files.fetch_files.error');
        }
      },
      async createFile(file: Omit<FileModel, 'id'>): Promise<void> {
        try {
          const createdFile = await http.post<FileModel>('/files', file);
          patchState(store, setEntity(createdFile));
        } catch (error) {
          toast.showErrorToast('files.create_file.error');
        }
      },
    })
  )
);
