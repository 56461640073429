import { computed, effect, inject } from '@angular/core';
import { SendChatMessageModel } from '@fieldos/components';
import { DialogService, NotificationsSocket } from '@fieldos/core';
import {
  ChatDataService,
  RightsDataService,
  SectionBasedEntityDataService,
  SectionsDataService,
  StatusGraphDataService,
} from '@fieldos/data-services';
import { LayoutDrawerFacade } from '@fieldos/facades';
import {
  ChatMessage,
  UploadedFileModel,
  GraphStatusType,
  PushNotificationScope,
  Section,
  SectionBasedEntity,
  SectionBasedEntityType,
  SectionRights,
  SectionSubType,
  SectionValueEntry,
  StatusGraphNode,
  StatusSection,
} from '@fieldos/models';
import {
  AuthStore,
  DPUStore,
  RoutesStore,
  WorkspaceStore,
} from '@fieldos/store/index';
import { ToastStore } from '@fieldos/store/toast.store';
import { normalize } from '@fieldos/utils';
import { TranslocoService } from '@ngneat/transloco';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { exhaustMap, from, tap } from 'rxjs';
import {
  RefuseReasonDialogData,
  RefuseReasonDialogResult,
} from './refuse-reason-dialog/refuse-reason-dialog.models';
import { SectionFormStatus } from './sections-form.models';

interface SectionsFormState<T extends SectionBasedEntity = SectionBasedEntity> {
  rights: Record<string, SectionRights | undefined> | undefined;
  entity: T | undefined;
  sectionsMap: Record<string, Section>;
  sectionIds: string[];
  sectionStatuses: Record<string, SectionFormStatus> | undefined;
  sectionValues: Record<string, Section['value']>;
  messages: ChatMessage[];
  sendingMessage: boolean;
  isChanged: boolean;
  entityType: SectionBasedEntityType | undefined;
  lastSectionChange: SectionValueEntry | undefined;
  error: boolean;
  statusGraph: StatusGraphNode[];
  markedForClose: boolean;

  isInitializing: boolean;

  isCreating: boolean;
  isUpdating: boolean;
}

const initialState: SectionsFormState = {
  rights: undefined,
  entity: undefined,
  sectionStatuses: undefined,
  sectionsMap: {},
  sectionValues: {},
  messages: [],
  sendingMessage: false,
  isChanged: false,
  entityType: undefined,
  lastSectionChange: undefined,
  error: false,
  statusGraph: [],
  markedForClose: false,

  isInitializing: false,
  isCreating: false,
  isUpdating: false,
  sectionIds: [],
};

export const SectionsFormStore = signalStore(
  withState<SectionsFormState>({ ...initialState }),
  withComputed((store) => ({
    isFormValid: computed(() =>
      store.sectionStatuses()
        ? Object.values(store.sectionStatuses() || {}).reduce(
            (acc, current) => acc && current.valid,
            true
          )
        : undefined
    ),
    isFormDirty: computed(() =>
      store.sectionStatuses()
        ? Object.values(store.sectionStatuses() || {}).reduce(
            (acc, current) => acc || current.dirty,
            false
          )
        : undefined
    ),
    isEditing: computed(() => store.entity() !== undefined),
  })),
  withMethods(
    (
      store,
      sectionsDataService = inject(SectionsDataService),
      rightsDataService: RightsDataService = inject(RightsDataService),
      statusGraphDataService = inject(StatusGraphDataService),
      chatService = inject(ChatDataService),
      workspaceStore = inject(WorkspaceStore),
      dataService = inject(SectionBasedEntityDataService),
      toastStore = inject(ToastStore),
      transloco = inject(TranslocoService),
      dialogService = inject(DialogService),
      layoutDrawerFacade = inject(LayoutDrawerFacade),
      auth = inject(AuthStore),
      routesStore = inject(RoutesStore),
      dpuStore = inject(DPUStore)
    ) => ({
      selectContainerSections: (sectionId: string): Section[] =>
        Object.values(store.sectionsMap()).filter(
          (s) => s.properties?.containerId === sectionId
        ),
      resetMarkedForClose(): void {
        patchState(store, { markedForClose: false });
      },
      setEntityType(entityType: SectionBasedEntityType): void {
        patchState(store, { entityType });
      },
      async reloadEntity() {
        const entity = store.entity() as SectionBasedEntity;
        const entityType = store.entityType() as SectionBasedEntityType;

        try {
          setTimeout(async () => {
            const result = await dataService.fetchOne(entity.id, entityType);

            this.initialize(result);
          }, 2000);
        } catch (error) {
          console.error(error);
        }
      },
      initialize: async (entity: SectionBasedEntity): Promise<void> => {
        patchState(store, {
          isInitializing: true,
          error: false,
          markedForClose: false,
        });
        const entityType = store.entityType() as SectionBasedEntityType;
        dpuStore.fetchAll();
        routesStore.fetchAll();

        try {
          const [{ sections }, rights, messages, statusGraph] =
            await Promise.all([
              sectionsDataService.fetchForScopeId(entity.scopeId, entityType),
              rightsDataService.fetchRightsForScope(
                entity.scopeId,
                auth.roleId() as number,
                entityType
              ),
              chatService.fetch(entity.id, entityType),
              statusGraphDataService.fetchForScopeId(
                entity.scopeId,
                entityType
              ),
            ]);

          patchState(store, {
            isInitializing: false,
            entity,
            sectionsMap: normalize(
              sections.map((e) => ({
                ...e,
                data: entity.value.find((f) => f.id === e.id)?.data,
              })),
              'id'
            ),
            sectionIds: sections.map((e) => e.id),
            messages,
            statusGraph,
            rights: rights.rights[entity.statusId].reduce(
              (acc, current) => ({
                ...acc,
                [current.sectionId]: current.rights,
              }),
              {}
            ),
            sectionStatuses: {},
            sectionValues: entity.value.reduce(
              (acc, value) => ({
                ...acc,
                [value.id]: value.value,
              }),
              {}
            ),
          });
        } catch (error) {
          patchState(store, { isInitializing: false, error: true });
        }
      },
      resetLastSectionChange(): void {
        patchState(store, { lastSectionChange: undefined });
      },

      setIsChanged(isChanged: boolean): void {
        patchState(store, { isChanged });
      },
      hardReset(): void {
        const entityType = store.entityType();
        patchState(store, { ...initialState, entityType });
      },
      async updateSectionValue<TSection extends Section = Section>(
        sectionId: string,
        value: TSection['value']
      ): Promise<void> {
        const entity = store.entity();
        const sectionsMap = store.sectionsMap();
        const sections = Object.values(sectionsMap);

        const previousValue = store.sectionValues()[
          sectionId
        ] as TSection['value'];

        patchState(store, (state) => ({
          sectionValues: {
            ...state.sectionValues,
            [sectionId]: value,
          },
        }));

        if (entity) {
          const isStatusChange =
            sections.find((section) => section.id === sectionId)?.subtype ===
            SectionSubType.Status;

          const convertedStatuses = store
            .statusGraph()
            .filter((e) => e.data.statusType === GraphStatusType.Converted)
            .map((e) => e.id);

          const refusedStatuses = store
            .statusGraph()
            .filter((e) => e.data.statusType === GraphStatusType.Refused)
            .map((e) => e.id);

          const isConvertedStatus =
            isStatusChange &&
            store.entityType() === 'servicerequests' &&
            convertedStatuses.includes(value as string);

          const isRefusedStatus =
            isStatusChange &&
            store.entityType() === 'servicerequests' &&
            refusedStatuses.includes(value as string);

          const isSetChange =
            store.entityType() === 'workorders' &&
            sections.find((section) => section.id === sectionId)?.subtype ===
              SectionSubType.Set;

          if (isSetChange) {
            this.reloadEntity();
          }

          if (isConvertedStatus) {
            return this.changeStatusToConverted(
              entity,
              sectionId,
              value as StatusSection['value'],
              previousValue as StatusSection['value']
            );
          } else if (isRefusedStatus) {
            return this.changeStatusToRefused(
              entity,
              sectionId,
              value as StatusSection['value'],
              previousValue as StatusSection['value']
            );
          } else {
            await dataService.updateSectionValue(
              entity.id,
              {
                id: sectionId,
                value,
              },
              store.entityType() as SectionBasedEntityType
            );

            patchState(store, (state) => ({
              isChanged: true,
              lastSectionChange: { id: sectionId, value },
              sectionValues: {
                ...state.sectionValues,
                [sectionId]: value,
              },
            }));
          }
        }
      },
      resetState: async (
        scopeId: number,
        prefilledValues?: Record<string, unknown>
      ): Promise<void> => {
        patchState(store, {
          isInitializing: true,
          entity: undefined,
          markedForClose: false,
          isCreating: false,
        });

        try {
          const [nodes, { sections }] = await Promise.all([
            statusGraphDataService.fetchForScopeId(scopeId, store.entityType()),
            sectionsDataService.fetchForScopeId(
              scopeId,
              store.entityType() as SectionBasedEntityType
            ),
          ]);

          const rights = await rightsDataService.fetchRightsForScope(
            scopeId,
            auth.roleId() as number,
            store.entityType() as SectionBasedEntityType
          );

          patchState(store, {
            entity: undefined,
            rights: rights.rights.addRights.reduce(
              (acc, current) => ({
                ...acc,
                [current.sectionId]: current.rights,
              }),
              {}
            ),
            statusGraph: nodes,
            sectionsMap: normalize(sections, 'id'),
            sectionIds: sections.map((e) => e.id),
            sectionStatuses: {},
            sectionValues: prefilledValues ? { ...prefilledValues } : {},
            isInitializing: false,
          });
        } catch (error) {
          patchState(store, { isInitializing: false, error: true });
        }
      },

      updateSelectedSectionStatus: (
        sectionId: string,
        status: SectionFormStatus
      ): void => {
        patchState(store, (state) => ({
          sectionStatuses: {
            ...state.sectionStatuses,
            [sectionId]: status,
          },
        }));
      },

      sendMessage: async ({
        message,
        files,
      }: SendChatMessageModel): Promise<void> => {
        const entity = store.entity();

        if (entity) {
          patchState(store, { sendingMessage: true });
          const storedMessage = await chatService.addMessage(
            entity.id,
            message,
            files,
            store.entityType() as SectionBasedEntityType
          );

          patchState(store, (state) => ({
            sendingMessage: false,
            messages: [
              ...state.messages,
              {
                ...storedMessage,
                userId: auth.userId() as number,
              },
            ],
          }));
        }
      },

      async create(): Promise<SectionBasedEntity | undefined> {
        patchState(store, { isCreating: true });
        const workOrderValue = store.sectionValues();
        const scopeId = workspaceStore.selectedWorkspaceScopeId();

        const sectionConfig = await sectionsDataService.fetchForScopeId(
          scopeId,
          store.entityType() as SectionBasedEntityType
        );

        try {
          const createdEntity = await dataService.create(
            {
              scopeId,
              sectionVersionId: sectionConfig.id,
              value: Object.keys(workOrderValue).map((sectionId) => ({
                id: sectionId,
                value: workOrderValue[sectionId],
              })),
              isReasonCodeLocked: null,
            },
            store.entityType() as SectionBasedEntityType
          );

          toastStore.showSuccessToast(
            `section_based_entity.${store.entityType()}.created_successfully.message`
          );

          return createdEntity;
        } catch (e) {
          toastStore.showErrorToast(
            `section_based_entity.${store.entityType()}.created_failed.message`
          );
          return;
        }
      },
      getSectionRights: (sectionId: string): SectionRights | void => {
        const rights = store.rights();
        if (rights) {
          return rights[sectionId];
        }
      },
      selectSectionStatus: (sectionId: string): SectionFormStatus | void => {
        const statuses = store.sectionStatuses();
        if (statuses) {
          return statuses[sectionId];
        }
      },
      changeStatusToConverted(
        entity: SectionBasedEntity,
        sectionId: string,
        value: StatusSection['value'],
        previousValue: StatusSection['value']
      ): void {
        dialogService
          .showConfirmDialog(
            'common.confirm',
            'section_based_entity.confirm_convert_to_work_order'
          )
          .pipe(
            tap(async (confirm) => {
              if (confirm) {
                await dataService.updateSectionValue(
                  entity.id,
                  {
                    id: sectionId,
                    value,
                  },
                  store.entityType() as SectionBasedEntityType
                );

                const workOrderId =
                  await dataService.convertServiceRequestToWorkOrder(entity.id);
                toastStore.showSuccessToast(
                  transloco.translate(
                    'section_based_entity.converted_to_work_order',
                    { workOrderId }
                  ),
                  7000,
                  [
                    {
                      label: 'common.view',
                      color: 'primary',
                      onClick: () => {
                        layoutDrawerFacade.openWorkOrder(workOrderId);
                      },
                    },
                  ]
                );

                patchState(store, (state) => ({
                  isChanged: true,
                  lastSectionChange: { id: sectionId, value },
                  markedForClose: true,
                  sectionValues: {
                    ...state.sectionValues,
                    [sectionId]: value,
                  },
                }));
              } else {
                patchState(store, (state) => ({
                  sectionValues: {
                    ...state.sectionValues,
                    [sectionId]: previousValue,
                  },
                }));
              }
            })
          )
          .subscribe();
      },
      changeStatusToRefused(
        entity: SectionBasedEntity,
        sectionId: string,
        value: StatusSection['value'],
        previousValue: StatusSection['value']
      ): void {
        const statuses = store.statusGraph();
        const sections = Object.values(store.sectionsMap());

        const status = statuses.find((e) => e.id === value);

        const photosSection = sections.find(
          (e) => e.subtype === SectionSubType.Media
        );

        const photosSectionValue =
          store.sectionValues()[photosSection?.id as string];

        if (status && status.data.statusType === GraphStatusType.Refused) {
          from(
            import(
              './refuse-reason-dialog/refuse-reason-dialog.component'
            ).then((m) => m.RefuseReasonDialogComponent)
          )
            .pipe(
              exhaustMap((component) =>
                dialogService
                  .openDialog<RefuseReasonDialogData, RefuseReasonDialogResult>(
                    component,
                    {
                      width: '50%',
                      height: '70%',
                      data: {
                        canShowPhotos: !!photosSection,
                        photos: (photosSectionValue || []) as UploadedFileModel[],
                        photosSectionId: photosSection?.id as string,
                        serviceRequestId: entity.id,
                      },
                    }
                  )
                  .pipe(
                    tap(async (reason?: RefuseReasonDialogResult) => {
                      if (reason) {
                        await dataService.updateSectionValue(
                          entity.id,
                          {
                            id: sectionId,
                            value,
                          },
                          store.entityType() as SectionBasedEntityType
                        );

                        if (photosSection?.id) {
                          await dataService.updateSectionValue(
                            entity.id,
                            {
                              id: photosSection?.id,
                              value: [...reason.photos],
                            },
                            store.entityType() as SectionBasedEntityType
                          );
                        }

                        await dataService.refuseServiceRequest(
                          entity.id,
                          reason.reason
                        );

                        patchState(store, (state) => ({
                          isChanged: true,
                          lastSectionChange: { id: sectionId, value },
                          markedForClose: true,
                          sectionValues: {
                            ...state.sectionValues,
                            [sectionId]: value,
                            [photosSection?.id as string]: [...reason.photos],
                          },
                        }));
                      } else {
                        patchState(store, (state) => ({
                          sectionValues: {
                            ...state.sectionValues,
                            [sectionId]: previousValue,
                          },
                        }));
                      }
                    })
                  )
              )
            )
            .subscribe();
        }
      },
    })
  ),
  withHooks({
    onInit: (
      store,
      socket = inject(NotificationsSocket),
      chatService = inject(ChatDataService)
    ): void => {
      effect(async () => {
        const notification = socket.latestNotification();
        if (notification) {
          const entity = store.entity();
          if (
            entity &&
            notification.scope === PushNotificationScope.NewComment
          ) {
            const messages = await chatService.fetch(
              entity.id,
              store.entityType() as SectionBasedEntityType
            );

            patchState(store, {
              messages,
            });
          }
        }
      });
    },
  })
);
