import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HeaderSidebarContentLayoutComponent } from '@fieldos/components';
import { DialogService } from '@fieldos/core';
import { HasPermissionDirective } from '@fieldos/directives';
import { FileModel } from '@fieldos/models';
import { FolderStore } from '@fieldos/store/files';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import { FolderFilesComponent } from './folder-files/folder-files.component';
import { FolderTreeComponent } from './folder-tree/folder-tree.component';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  standalone: true,
  imports: [
    HeaderSidebarContentLayoutComponent,
    FolderTreeComponent,
    TranslocoModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    FolderFilesComponent,
    HasPermissionDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesComponent {
  constructor() {
    this.folderStore.fetchFolders();
  }

  public readonly files = input.required<FileModel[]>();
  public readonly selectedFolderId = input.required<number>();

  public readonly addFile = output<void>();
  public readonly selectFolder = output<number>();

  protected readonly folderStore = inject(FolderStore);

  private readonly _dialog = inject(DialogService);

  protected async openDeleteFolderDialog(): Promise<void> {
    const canDelete = await lastValueFrom(this._dialog.showConfirmDialog());

    if (canDelete) {
      this.folderStore.deleteSelectedFolder();
    }
  }

  protected async openEditFolderDialog(): Promise<void> {
    const folderName = this.folderStore.selectedFolder()?.name || '';
    const newName = await lastValueFrom(
      this._dialog.openTextFieldDialog(
        'files.edit_folder',
        'files.folder_name',
        folderName
      )
    );

    if (newName && newName !== folderName) {
      this.folderStore.updateFolder(newName);
    }
  }
}
