import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FileUploaderComponent } from '@fieldos/components';
import { FileUploadFacade } from '@fieldos/core';
import { UploadedFileModel } from '@fieldos/models';
import { FileStore } from '@fieldos/store/files';
import { ToastStore } from '@fieldos/store/toast.store';
import { toServerDate } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import { AddFilesDialogData } from './add-files-dialog.models';
import { ProcessUploadedFilesComponent } from './process-uploaded-files/process-uploaded-files.component';
import { ProcessedFileModel } from './process-uploaded-files/process-uploaded-files.models';

@Component({
  selector: 'app-add-files-dialog',
  templateUrl: './add-files-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    TranslocoModule,
    FileUploaderComponent,
    MatButtonModule,
    ProcessUploadedFilesComponent,
  ],
})
export class AddFilesDialogComponent {
  protected readonly data = inject<AddFilesDialogData>(MAT_DIALOG_DATA);

  protected readonly _fileStore = inject(FileStore);

  protected readonly filesToUpload = signal<UploadedFileModel[]>([]);
  protected readonly uploadedFiles = signal<UploadedFileModel[]>([]);
  protected readonly processedFiles = signal<ProcessedFileModel[]>([]);

  protected readonly isProcessing = signal<boolean>(false);

  protected readonly canSave = computed(
    () =>
      this.uploadedFiles().every((f) => !!f.id && this.isProcessing()) &&
      this.uploadedFiles().length
  );

  private readonly _uploader = inject(FileUploadFacade);
  private readonly _dialogRef = inject(MatDialogRef);
  private readonly _toast = inject(ToastStore);

  protected async onFilesChange(files: UploadedFileModel[]) {
    this.filesToUpload.set(files);
  }

  protected async processFiles() {
    const filesToUpload = this.filesToUpload();
    const files = filesToUpload.map((f) => f.file).filter((f) => !!f);

    this.isProcessing.set(true);

    this.uploadedFiles.set(this.filesToUpload());

    const uploadedFiles = await lastValueFrom(
      this._uploader.uploadFiles(files, 'files', 'file')
    );

    this.uploadedFiles.set(uploadedFiles);
  }

  protected onProcessedFilesChange(files: ProcessedFileModel[]): void {
    this.processedFiles.set(files);
  }

  protected async saveFiles(): Promise<void> {
    try {
      const requests = this.processedFiles().map((file, index) => {
        const fileModel = this.uploadedFiles()[index];

        return this._fileStore.createFile({
          name: fileModel.name,
          categoryId: file.folderId,
          entityId: file.entityId,
          expiryDate: file.expiryDate ? toServerDate(file.expiryDate) : null,
          fileId: fileModel.id,
          issueDate: file.issueDate ? toServerDate(file.issueDate) : null,
          moduleId: file.module,
          mimeType: fileModel.mimeType,
          size: fileModel.size,
          tagIds: file.tags,
          url: fileModel.url,
          nameOnDisk: fileModel.name,
          thumbnailUrl: fileModel.thumbnailUrl,
        });
      });

      await Promise.all(requests);
      this._toast.showSuccessToast('files.create.success');
      this._dialogRef.close(true);
    } catch (error) {
      this._toast.showErrorToast('files.create.error');
    }
  }
}
