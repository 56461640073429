<h1 class="text-center font-bold text-base">
  @if (data.parentId && data.reasonCodeName) {
    {{ 'assign_reason_code.validate_title' | transloco }}
  } @else {
    {{ 'assign_reason_code.title' | transloco }}
  }
</h1>
<mat-dialog-content>
  <form
    novalidate
    class="flex flex-col gap-4 overflow-auto overflow-x-hidden"
    [formGroup]="form">
    @if (data.parentId && data.reasonCodeName) {
      <app-assign-exception-parent-code
        [exceptionId]="data.parentId"
        [reasonCodeName]="data.reasonCodeName" />
    }

    @if (data.reasonCodeName) {
      <mat-divider />

      <h1 class="text-center font-bold text-base">
        {{ 'assign_reason_code.approve' | transloco }}
      </h1>
    }

    <app-autocomplete-select
      formControlName="taxonomyId"
      [errors]="form.controls.taxonomyId.errors | fieldErrors"
      [options]="reasonCodes()"
      label="{{ 'assign_reason_code.reason_code' | transloco }}"
      placeholder="{{ 'assign_reason_code.reason_code' | transloco }}"
      required />

    <app-file-uploader
      class="max-h-48 h-48"
      [accept]="['image/*']"
      (fileClick)="onFileClick($event)"
      (filesChange)="onFilesChange($event)" />

    <app-text-field
      formControlName="comments"
      isTextarea
      label="{{ 'common.comments' | transloco }}"
      placeholder="{{ 'common.comments' | transloco }}" />

    @if (reasonCodeAction(); as reasonCodeAction) {
      <div>
        <div class="flex justify-center text-base font-bold w-full">
          {{ 'assign_reason_code.action' | transloco }}
        </div>
        @switch (reasonCodeAction) {
          @case ('changedestinationlocationinwo') {
            <app-autocomplete-select
              [options]="locations()"
              (selectionChange)="onLocationChange($event)"
              label="{{
                'assign_reason_code.destination_location' | transloco
              }}"
              placeholder="{{
                'assign_reason_code.destination_location' | transloco
              }}" />
          }

          @case ('changeseal') {
            <app-assign-exception-add-seals-action
              (sealsChange)="onSealsChange($event)" />
          }

          @case ('changeDPUandUnlockDPUSection') {
            <app-assign-exception-select-dpus-action
              (valueChange)="onDPUsChange($event)" />
          }

          @case ('transferWorkOrders') {
            <app-assign-exception-transfer-work-orders-action
              [exceptionId]="data.parentId || 0" />
          }

          @case ('changestatusofwo') {
            <div class="flex flex-col items-center">
              <div class="text-base">
                {{ 'assign_reason_code.change_work_order_status' | transloco }}
              </div>
              <div class="font-bold">
                {{ statusName() }}
              </div>
            </div>
          }
        }
      </div>
    }
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'common.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    [disabled]="form.invalid"
    [mat-dialog-close]="form.value"
    color="primary">
    {{ 'common.confirm' | transloco }}
  </button>
</mat-dialog-actions>
