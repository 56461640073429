import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AddFilesDialogComponent } from '@fieldos/business/files';
import { AddFilesDialogData } from '@fieldos/business/files/add-files-dialog/add-files-dialog.models';
import { DialogService } from '@fieldos/core';
import {
  ChecklistTaskConditionActionTask,
  ChecklistYesNoNaTask,
  ChecklistYesNoNaTaskOption,
  FileModule,
  LocationSectionValue,
  SectionSubType,
} from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import { ChecklistTaskViewerBaseComponent } from '../checklist-task-viewer-base.component';

@Component({
  selector: 'app-checklist-yes-no-task-viewer',
  templateUrl: './checklist-yes-no-task-viewer.component.html',
  standalone: true,
  imports: [MatButtonModule, TranslocoModule, MatButtonToggleModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistYesNoTaskViewerComponent extends ChecklistTaskViewerBaseComponent<ChecklistYesNoNaTask> {
  private readonly _dialog = inject(DialogService);

  protected override async executeAction(
    value: ChecklistYesNoNaTaskOption
  ): Promise<ChecklistYesNoNaTaskOption | false> {
    const action = this.task().conditionals?.[value];

    if (action) {
      const result = await this._executeAction(action);

      if (result) {
        return value;
      } else {
        return false;
      }
    }

    return value;
  }

  private async _executeAction(
    actionType: ChecklistTaskConditionActionTask
  ): Promise<boolean> {
    const entity = this.sectionsFormStore?.entity();
    const sections = Object.values(this.sectionsFormStore?.sectionsMap() ?? {});

    if (!entity && !sections.length) {
      return Promise.resolve(false);
    }

    switch (actionType) {
      case 'uploadfileonlocation':
        const locationSection = sections.find(
          (s) => s.subtype === SectionSubType.Location
        );
        const locationValue: LocationSectionValue = entity?.value.find(
          (e) => e.id === locationSection?.id
        )?.value as LocationSectionValue;

        const success = await lastValueFrom(
          this._dialog.openDialog<AddFilesDialogData, boolean>(
            AddFilesDialogComponent,
            {
              data: {
                folderId: 0,
                module: FileModule.Locations,
                entityId: locationValue.locationId,
              },
            }
          )
        );

        return Promise.resolve(!!success);

      default:
        return Promise.resolve(true);
    }
  }
}
