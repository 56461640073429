import { inject, Injectable } from '@angular/core';
import { UploadedFileModel, SectionSubType } from '@fieldos/models';
import { forkJoin, Observable, of } from 'rxjs';
import { FileUploadDataService } from './file-upload.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadFacade {
  private readonly _dataService = inject(FileUploadDataService);

  uploadFile(
    file: File,
    module: string,
    type: string = 'media'
  ): Observable<UploadedFileModel> {
    return this._dataService.uploadFile(file, module, type);
  }

  uploadFiles(
    files: File[],
    module: string,
    type: string = 'media'
  ): Observable<UploadedFileModel[]> {
    return forkJoin(files.map((file) => this.uploadFile(file, module, type)));
  }

  uploadSectionFiles(
    files: UploadedFileModel[],
    sectionId: string,
    sectionType: SectionSubType
  ): Observable<UploadedFileModel[]> {
    return forkJoin(
      files.map((file) => {
        if (file.file && !file.id) {
          return this._dataService.uploadSectionFile(
            file.file,
            sectionId,
            sectionType
          );
        }

        return of(file);
      })
    );
  }

  uploadSectionFile(
    file: File,
    sectionId: string,
    sectionType: SectionSubType
  ): Observable<UploadedFileModel> {
    return this._dataService.uploadSectionFile(file, sectionId, sectionType);
  }

  uploadSectionFileWithProgress(
    file: File,
    sectionId: string,
    sectionType: SectionSubType,
    value: string = ''
  ): Observable<UploadedFileModel | number> {
    return this._dataService.uploadSectionFileWithProgress(
      file,
      sectionId,
      sectionType,
      value
    );
  }

  uploadFieldFileWithProgress(file: File): Observable<UploadedFileModel | number> {
    return this._dataService.uploadFileWithProgress(file, 'fields');
  }
}
